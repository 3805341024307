import React, { useState, useEffect, Suspense, useMemo, useRef } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import MainLayoutWrapper from '../../../components/Layout/MainLayoutWrapper';
import supabaseConnect from '../../../utils/supabaseConnect';
import CountdownOverlay from '../../../components/CountdownOverlay/CountdownOverlay';
import { createDefaultLogoImage } from '../../../utils/logoCreatorUtils';

const LazyMainEditor = React.lazy(() => import('../../../editor/index'));

interface CreateVendorProps {
  isStaffAdmin?: boolean;
}

interface Category {
  id: number;
  name: string;
}

const CreateVendor: React.FC<CreateVendorProps> = ({ isStaffAdmin }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [description, setDescription] = useState('');
  const [info, setInfo] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [createdVendorId, setCreatedVendorId] = useState<number | null>(null);
  const [showCountdownOverlay, setShowCountdownOverlay] = useState(false);
  const [categories, setCategories] = useState<{ id: number; name: string }[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<{ id: number; name: string }[]>([]);
  const [categorySearch, setCategorySearch] = useState('');
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const categoryDropdownRef = useRef<HTMLDivElement>(null);
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [pendingDeleteCategory, setPendingDeleteCategory] = useState<number | null>(null);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const supabase = supabaseConnect();
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('profile_role')
          .select('role_id')
          .eq('user_id', user.id)
          .single();

        if (data && data.role_id === 1) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }
    };

    const fetchCategories = async () => {
      const supabase = supabaseConnect();
      const { data, error } = await supabase
        .from('categories')
        .select('id, name');
      
      if (error) {
        console.error('Error fetching categories:', error);
      } else {
        setCategories(data || []);
      }
    };

    checkAdminStatus();
    fetchCategories();
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (categoryDropdownRef.current && !categoryDropdownRef.current.contains(event.target as Node)) {
        setShowCategoryDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const validateForm = () => {
    if (!name || !description || !info || selectedCategories.length === 0) {
      setError('Name, description, detailed information and at least one category are required');
      return false;
    }
    if (name === 'N/A' || description === 'N/A' || info === 'N/A') {
      return true;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
    setError(null);
    setSuccess(false);

    const supabase = supabaseConnect();
    
    try {
      // Get current user's profile ID
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('No authenticated user found');
      }

      let finalLogoUrl = logoUrl;

      // Generate default logo if none was uploaded
      if (!logoUrl) {
        console.log('Creating default logo');
        const defaultLogo = await createDefaultLogoImage(name);
        console.log('defaultLogo created:', defaultLogo);
        
        if (defaultLogo) {
          const fileName = `${Date.now()}_${name.replace(/\s+/g, '_')}.png`;
          const { data: uploadData, error: uploadError } = await supabase.storage
            .from('media')
            .upload(fileName, defaultLogo);

          if (uploadError) {
            console.error('Upload error:', uploadError);
            throw uploadError;
          }

          console.log('Logo uploaded:', uploadData);

          const { data: publicURL } = supabase.storage
            .from('media')
            .getPublicUrl(fileName);

          if (publicURL) {
            finalLogoUrl = publicURL.publicUrl;
            console.log('Final logo URL:', finalLogoUrl);
          }
        }
      }

      // Create vendor with profile_id
      const { data, error } = await supabase
        .from('vendors')
        .insert([
          { 
            name, 
            phone, 
            email, 
            logo_url: finalLogoUrl,
            description, 
            info, 
            website_url: websiteUrl,
            profile_id: user.id
          }
        ])
        .select();

      if (error) throw error;

      const vendorId = data[0].id;

      // Insert vendor categories
      const vendorCategories = selectedCategories.map(category => ({
        vendor_id: vendorId,
        category_id: category.id
      }));

      const { error: categoriesError } = await supabase
        .from('vendor_categories')
        .insert(vendorCategories);

      if (categoriesError) throw categoriesError;

      setLogoUrl(finalLogoUrl);
      setCreatedVendorId(vendorId);
      setSuccess(true);
      setShowCountdownOverlay(true);
      setName('');
      setPhone('');
      setEmail('');
      setLogoUrl('');
      setDescription('');
      setInfo('');
      setWebsiteUrl('');
      setSelectedCategories([]);
    } catch (error) {
      console.error('Error creating vendor:', error);
      setError('Failed to create vendor. Please try again.');
    }
    
    setShowConfirmation(false);
  };

  const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const supabase = supabaseConnect();
      const { data, error } = await supabase.storage
        .from('media')
        .upload(`${Date.now()}_${file.name}`, file);

      if (error) {
        setError('Failed to upload logo');
      } else if (data) {
        const { data: publicURL } = supabase.storage
          .from('media')
          .getPublicUrl(data.path);

        if (publicURL) {
          setLogoUrl(publicURL.publicUrl);
        }
      }
    }
  };

  const handleConfirmSubmit = () => {
    setShowConfirmation(true);

    if (success && countdown === null) {
      setCountdown(3);
    }

    if (countdown !== null && countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }

    if (countdown === 0 && createdVendorId) {
      window.location.href = `/recommended-vendors/${createdVendorId}`;
    }
  };

  const handleCancelSubmit = () => {
    setShowConfirmation(false);
  };

  const filteredCategories = useMemo(() => {
    return categories.filter(category => 
      category.name.toLowerCase().includes(categorySearch.toLowerCase()) &&
      !selectedCategories.some(selected => selected.id === category.id)
    );
  }, [categories, categorySearch, selectedCategories]);

  const handleCategorySelect = (category: { id: number; name: string }) => {
    setSelectedCategories([...selectedCategories, category]);
    setCategorySearch('');
  };

  const handleCategoryRemove = (categoryId: number) => {
    setSelectedCategories(selectedCategories.filter(cat => cat.id !== categoryId));
  };

  const handleCreateCategory = async () => {
    if (!newCategoryName.trim()) return;

    const supabase = supabaseConnect();
    try {
      // Check if category already exists
      const { data: existingCategory } = await supabase
        .from('categories')
        .select('id, name')
        .ilike('name', newCategoryName.trim())
        .single();

      if (existingCategory) {
        setError('Category already exists');
        return;
      }

      // Create new category
      const { data: newCategory, error } = await supabase
        .from('categories')
        .insert([{ name: newCategoryName.trim() }])
        .select()
        .single();

      if (error) throw error;

      // Add new category to categories list and selected categories
      setCategories([...categories, newCategory]);
      setSelectedCategories([...selectedCategories, newCategory]);
      
      // Reset new category state
      setNewCategoryName('');
      setIsCreatingCategory(false);
      setCategorySearch('');
      setShowCategoryDropdown(false);
    } catch (error) {
      console.error('Error creating category:', error);
      setError('Failed to create category');
    }
  };

  const handleDeleteCategory = async (categoryId: number) => {
    // Clear any existing error messages
    setError(null);
    
    const supabase = supabaseConnect();
    try {
      // First delete all vendor_categories associations
      const { error: deleteAssociationsError } = await supabase
        .from('vendor_categories')
        .delete()
        .eq('category_id', categoryId);

      if (deleteAssociationsError) throw deleteAssociationsError;

      // Then delete the category itself
      const { error: deleteCategoryError } = await supabase
        .from('categories')
        .delete()
        .eq('id', categoryId);

      if (deleteCategoryError) throw deleteCategoryError;

      // Update local state
      setCategories(categories.filter(cat => cat.id !== categoryId));
      setSelectedCategories(selectedCategories.filter(cat => cat.id !== categoryId));
      setCategorySearch('');
      setPendingDeleteCategory(null);
    } catch (error) {
      console.error('Error deleting category:', error);
      setError('Failed to delete category');
      setPendingDeleteCategory(null);
    }
  };

  return (
    <div className={`flex w-full ${isStaffAdmin ? '' : 'h-screen'} overflow-hidden text-white`}>
      {!isStaffAdmin && <Sidebar />}
      <MainLayoutWrapper>
        <div className="p-3 max-w-3xl">
          <h1 className="text-2xl font-bold mb-4">Create Recommended Vendor</h1>
          <form onSubmit={handleSubmit} className="space-y-5 flex flex-col">
            <div >
              <label htmlFor="name" className="block mb-2">Name</label>
              <input
                type="text"
                id="name"
                aria-label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg border-solid border-[.2px] border-lightGray"
                required
              />
            </div>
            <div>
              <label htmlFor="phone" className="block mb-2">Phone</label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg border-solid border-[.2px] border-lightGray"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg border-solid border-[.2px] border-lightGray"
                required
              />
            </div>
            <div>
              <label htmlFor="website" className="block mb-2">Website</label>
              <input
                type="url"
                id="website"
                aria-label="Website"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg border-solid border-[.2px] border-lightGray"
              />
            </div>
            <div>
              <label htmlFor="logo" className="block mb-2">Logo</label>
              <input
                type="file"
                id="logo"
                accept="image/*"
                onChange={handleLogoUpload}
                className="pb-3"
              />
            </div>
            <div>
              <label htmlFor="description" className="block mb-2">Description</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg resize-vertical border-solid border-[.2px] border-lightGray"
                rows={1}
                style={{ resize: 'none' }}
                required
              ></textarea>
            </div>
            <div className="relative" ref={categoryDropdownRef}>
              <label className="block mb-2">
                Categories <span className="text-red-500">*</span>
              </label>
              <div className="flex flex-wrap gap-2 mb-2">
                {selectedCategories.map(category => (
                  <div key={category.id} className="flex items-center bg-gray-700 rounded-full px-3 py-1">
                    <span>{category.name}</span>
                    <button
                      type="button"
                      onClick={() => handleCategoryRemove(category.id)}
                      className="ml-2 focus:outline-none text-sm font-bold"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
              <input
                type="text"
                placeholder={isAdmin ? "Search or create new category..." : "Search categories..."}
                value={isCreatingCategory ? newCategoryName : categorySearch}
                onChange={(e) => {
                  if (isCreatingCategory) {
                    setNewCategoryName(e.target.value);
                  } else {
                    setCategorySearch(e.target.value);
                  }
                }}
                onClick={() => setShowCategoryDropdown(true)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg border-solid border-[.2px] border-lightGray"
              />
              {showCategoryDropdown && (
                <div className="absolute z-10 w-full mt-1 bg-gray-700 border border-gray-600 rounded-md shadow-lg max-h-60 overflow-y-auto">
                  {isCreatingCategory ? (
                    <div className="p-4">
                      <div className="flex items-center justify-between">
                        <span>Create new category:</span>
                        <div className="flex gap-2">
                          <button
                            onClick={handleCreateCategory}
                            className="px-3 py-1 bg-communityBlue text-white rounded hover:bg-blue-400 cursor-pointer"
                          >
                            Create
                          </button>
                          <button
                            onClick={() => {
                              setIsCreatingCategory(false);
                              setNewCategoryName('');
                            }}
                            className="px-3 py-1 bg-gray-600 text-white rounded hover:bg-gray-500 cursor-pointer"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {filteredCategories.map(category => (
                        <div
                          key={category.id}
                          className="px-4 py-2 hover:bg-gray-600 cursor-pointer group flex items-center justify-between"
                        >
                          <div 
                            className="flex-grow"
                            onClick={() => handleCategorySelect(category)}
                          >
                            {category.name}
                          </div>
                          {isAdmin && (
                            <button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (pendingDeleteCategory === category.id) {
                                  handleDeleteCategory(category.id);
                                } else {
                                  setPendingDeleteCategory(category.id);
                                }
                              }}
                              onMouseLeave={() => setPendingDeleteCategory(null)}
                              className={`
                                opacity-0 group-hover:opacity-100 px-2 transition-all duration-200 cursor-pointer
                                ${pendingDeleteCategory === category.id ? 'text-red-600' : 'text-red-500 hover:text-red-300'}
                              `}
                              aria-label={pendingDeleteCategory === category.id ? 
                                `Confirm delete ${category.name} category` : 
                                `Delete ${category.name} category`
                              }
                            >
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                className={`h-5 w-5 transform transition-transform duration-200 ${
                                  pendingDeleteCategory === category.id ? 'scale-110' : ''
                                }`}
                                viewBox="0 0 20 20" 
                                fill="currentColor"
                              >
                                {pendingDeleteCategory === category.id ? (
                                  <path 
                                    fillRule="evenodd" 
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                ) : (
                                  <path 
                                    fillRule="evenodd" 
                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" 
                                    clipRule="evenodd"
                                  />
                                )}
                              </svg>
                            </button>
                          )}
                        </div>
                      ))}
                      {isAdmin && categorySearch && (
                        <div
                          className="px-4 py-2 hover:bg-gray-600 cursor-pointer text-communityBlue"
                          onClick={() => {
                            setIsCreatingCategory(true);
                            setNewCategoryName(categorySearch);
                          }}
                        >
                          + Create "{categorySearch}"
                        </div>
                      )}
                      {filteredCategories.length === 0 && !isAdmin && (
                        <div className="px-4 py-2 text-gray-400">
                          No categories found
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="info" className="block mb-2" aria-label="Detailed Information">Detailed Information</label>
              <div className="w-full">
                {editorLoaded ? (
                  <Suspense fallback={<div>Loading editor...</div>}>
                    <div className="">
                    <LazyMainEditor editor={info} setEditor={setInfo} body={info} data-testid="detailed-info-editor" />
                    </div>
                  </Suspense>
                ) : (
                  <textarea
                    value={info}
                    onChange={(e) => setInfo(e.target.value)}
                    className="w-full pb-3 bg-gray-800 rounded text-white text-lg resize-vertical border-solid border-[.2px] border-lightGray"
                    rows={10}
                  ></textarea>
                )}
              </div>
            </div>
            {error && <p className="text-red-500">{error}</p>}
            {success && (
              <div className="text-green-500">
                <p>Vendor created successfully!</p>
                {countdown !== null && (
                  <p>Redirecting in {countdown} seconds...</p>
                )}
              </div>
            )}
            {showConfirmation ? (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                <div className="bg-gray-800 p-6 rounded-lg max-w-sm w-full border-2 border-communityBlue shadow-xl transform transition-all">
                  <p className="text-lg font-medium mb-[3rem]">Are you sure you want to create this vendor?</p>
                  <div className="mt-4 flex justify-end space-x-3">
                    <button 
                      onClick={handleSubmit} 
                      className="bg-gray-700 hover:bg-communityBlue text-white px-6 py-2 rounded-md w-fit cursor-pointer transition-colors duration-200 shadow-sm"
                    >
                      Confirm
                    </button>
                    <button 
                      onClick={handleCancelSubmit} 
                      className="bg-gray-700 hover:bg-red-600 text-white px-6 py-2 rounded-md w-fit cursor-pointer transition-colors duration-200 shadow-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <button 
                onClick={handleConfirmSubmit} 
                className="bg-gray-800 hover:bg-communityBlue text-white px-6 py-2 rounded w-fit cursor-pointer"
              >
                Create Vendor
              </button>
            )}
            {showCountdownOverlay && (
              <CountdownOverlay
                initialCountdown={3}
                onComplete={() => window.location.href = `/recommended-vendors/${createdVendorId}`}
              />
            )}
          </form>
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default CreateVendor;