import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Notification, fetchNotifications, markNotificationAsRead, markAllNotificationsAsRead } from '../Services/NotificationService';
import LoadingSpinner from '../../Loading/LoadingSpinner';

interface NotificationMenuProps {
  isOpen: boolean;
  onClose: () => void;
  onUnreadCountChange: (count: number) => void;
  notifications?: {
    id: string;
    title: string;
    timestamp: string;
    category: string;
    read: boolean;
  }[];
}

const NotificationMenu: React.FC<NotificationMenuProps> = ({ isOpen, onClose, onUnreadCountChange }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      loadNotifications();
    }
  }, [isOpen]);

  useEffect(() => {
    if (notifications) {
      const unreadCount = notifications.filter(n => !n.read).length;
      onUnreadCountChange(unreadCount);
    }
  }, [notifications, onUnreadCountChange]);

  const loadNotifications = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await fetchNotifications();
      setNotifications(data);
    } catch (err) {
      setError('Failed to load notifications');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotificationClick = async (notification: Notification, event: React.MouseEvent | React.KeyboardEvent) => {
    // Prevent event from bubbling up to parent containers
    event.stopPropagation();
    
    if (!notification.read) {
      try {
        await markNotificationAsRead(notification.id);
        const updatedNotifications = notifications.map(n => 
          n.id === notification.id ? { ...n, read: true } : n
        );
        setNotifications(updatedNotifications);
        onUnreadCountChange(updatedNotifications.filter(n => !n.read).length);
      } catch (err) {
        console.error('Error marking notification as read:', err);
      }
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllNotificationsAsRead();
      const updatedNotifications = notifications.map(n => ({ ...n, read: true }));
      setNotifications(updatedNotifications);
      onUnreadCountChange(0);
    } catch (err) {
      console.error('Error marking all notifications as read:', err);
    }
  };

  const formatTimeAgo = (dateString: string) => {
    const date = DateTime.fromISO(dateString);
    return date.toRelative();
  };

  if (!isOpen) return null;

  return (
    <div 
      className="absolute right-0 mt-2 w-[380px] bg-[#1f1f23] border border-[#2d2d2d] rounded-lg shadow-lg z-50 overflow-hidden"
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      {/* Header */}
      <div className="flex items-center p-4 border-b border-[#2d2d2d] relative bg-[#1f1f23] shadow-[0_1px_3px_0_rgba(0,0,0,0.3)] z-10">
        <div className="flex items-center gap-2 absolute left-4">
          <svg 
            className="w-5 h-5 text-gray-400" 
            fill="none" 
            strokeWidth="2"
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" 
            />
          </svg>
        </div>
        <h2 className="text-base font-medium text-white flex-1 text-center">Notifications</h2>
        <div 
          className="absolute right-4 text-gray-400 hover:text-white cursor-pointer"
          onClick={onClose}
          role="button"
          tabIndex={0}
          aria-label="Close notifications"
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onClose();
            }
          }}
        >
          <svg 
            className="w-5 h-5" 
            fill="none" 
            strokeWidth="2" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      </div>

      {/* Notification List */}
      <div className="max-h-[400px] overflow-y-auto">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center p-8 space-y-3">
            <LoadingSpinner />
          </div>
        ) : error ? (
          <div className="p-6 text-center text-red-400">
            <svg className="w-8 h-8 mx-auto mb-2" fill="none" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>
            {error}
          </div>
        ) : notifications.length === 0 ? (
          <div className="flex flex-col items-center justify-center p-8 text-center">
            <svg className="w-6 h-6 mb-2 text-gray-500" fill="none" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
            </svg>
            <p className="text-white text-sm">No new notifications</p>
          </div>
        ) : (
          notifications.map((notification) => (
            <div 
              key={notification.id}
              onClick={(e) => handleNotificationClick(notification, e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleNotificationClick(notification, e);
                }
              }}
              role="button"
              tabIndex={0}
              className={`p-4 border-b border-[#2d2d2d] hover:bg-gray-600 cursor-pointer transition-colors duration-200 ${
                !notification.read ? 'bg-gray-800' : ''
              }`}
            >
              <div className="flex flex-col gap-1">
                <span className="text-sm font-medium text-white">{notification.title}</span>
                {notification.message && (
                  <span className="text-sm text-gray-400">{notification.message}</span>
                )}
                <div className="flex items-center gap-2 mt-1">
                  <span className="text-xs text-gray-400">{formatTimeAgo(notification.created_at)}</span>
                  <span className="text-xs text-gray-600">•</span>
                  <span className="text-xs text-communityBlue font-medium">{notification.category}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Footer */}
      {notifications.length > 0 && notifications.some(n => !n.read) && (
        <div className="p-3 border-t border-[#2d2d2d] bg-[#18181b]">
          <button 
            onClick={handleMarkAllAsRead}
            className="w-full text-sm text-white hover:bg-communityBlue py-2 rounded-md transition-colors duration-200 bg-[#1f1f23] cursor-pointer"
          >
            Mark All as Read
          </button>
        </div>
      )}
    </div>
  );
};

export default NotificationMenu;