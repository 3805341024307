import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { analyticsService } from '../services/analyticsService';
import useUser from '../../utils/supabase/useUser';
import { SessionData } from '../types/analytics.types';

/**
 * Gets the device type based on user agent
 * @returns Device type string ('mobile', 'tablet', or 'desktop')
 */
export const getDeviceType = (): string => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
};

/**
 * Gets browser information from user agent
 * @returns Browser name and version
 */
export const getBrowserInfo = (): string => {
  const ua = navigator.userAgent;
  if (ua.includes('Firefox/')) return 'Firefox';
  if (ua.includes('Chrome/')) return 'Chrome';
  if (ua.includes('Safari/')) return 'Safari';
  if (ua.includes('Edge/')) return 'Edge';
  return 'Unknown';
};

/**
 * Hook for tracking user sessions
 * @returns Object containing session ID and tracking methods
 */
export const useSessionTracking =  () => {
    const [sessionId, setSessionId] = useState<string>('');
    const { user } = useUser();

  useEffect(() => {
    const initSession = async () => {
      const newSessionId = uuidv4();
      setSessionId(newSessionId);

      const sessionData = {
        session_id: newSessionId,
        user_id: user?.id,
        user_agent: navigator.userAgent,
        device_type: getDeviceType(),
        browser: getBrowserInfo(),
        start_time: new Date(),
      };

      await analyticsService.createSession(sessionData as SessionData);
    };

    if (user) {
      initSession();
    }

    return () => {
      if (sessionId && user) {
        analyticsService.updateSessionEndTime(sessionId, new Date());
      }
    };
  }, [user]);

  return { sessionId };
};
