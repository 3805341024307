import React from 'react';
import { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import ChatbotBubble from './components/Chatbot/ChatbotBubble';
import supabaseConnect from './utils/supabaseConnect';
import { AnalyticsProvider } from './analytics/components/AnalyticsProvider';
import ArchivedAnnouncements from './pages/announcement/archived';

// Import all components
import Dashboard from './pages/dashboard';
import Login from './pages/auth/Login';
import SignUp from './pages/auth/SignUp';
import SearchResults from './pages/search/SearchResults';
import ResourcesPageHome from './pages/resourcesPage/ResourcesPageHome';
import ResourcesPage from './pages/resourcesPage/ResourcesPage';
import TrainingHomePage from './pages/training';
import Partners from './pages/partners/Partners';
import PartnerDetails from './pages/partners/PartnerDetails';
import Announcement from './pages/announcement';
import Profile from './pages/profile/';
import EditProfile from './pages/profile';
import Inactive from './pages/inactive';
import ChangePassword from './pages/auth/ChangePassword';
import RequestPasswordChange from './pages/auth/RequestPasswordChange';
import ConfirmEmailForPasswordChange from './pages/auth/EmailSentConfirmation';
import Vendors from './pages/Recommended_Vendors/Vendors';
import VendorDetails from './pages/Recommended_Vendors/VendorDetails';
import EventsPage from './pages/eventsPage/EventsPage';
import StaffAndAdminPage from './pages/staff_and_admin';
import EditAnnouncement from './pages/admin/announcements/editAnnouncement';
import EditPartner from './pages/admin/partners/EditPartner';
import EditVendor from './pages/admin/vendors/EditVendor';
import CreateVendor from './pages/admin/vendors/Create_Vendor';
import CreatePartner from './pages/admin/partners/Create_Partner';
import EditUser from './pages/admin/users/editUsers';
import ModalTest from "./pages/test/ModalTest";
import WeeklyAnnouncementHandler from './components/UpdateModal/handlers/WeeklyAnnouncementHandler';
import { updateLastActive } from './utils/lastActive';
import MarketingCenterMain from './pages/marketing_center/marketingCenterMain';


// check with supabase if the user is authenticated
const supabase = supabaseConnect();

/**
 * ProtectedRoute component that checks if user is authenticated
 * @param children - The route component to render if authenticated
 * @param isLoggedIn - Authentication state from parent
 * @returns The protected route or redirects to login
 */
const ProtectedRoute = ({ 
  children, 
  isLoggedIn 
}: { 
  children: React.ReactNode;
  isLoggedIn: boolean;
}) => {
  return isLoggedIn ? <>{children}</> : <Navigate to="/login" />;
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Single useEffect for auth handling
  useEffect(() => {
    console.log('Setting up auth handling');
    
    // Initial auth check
    const checkInitialAuth = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        console.log('Initial auth check:', { hasSession: !!session });
        setIsLoggedIn(!!session);
        
        if (session?.user.id) {
          await updateLastActive(session.user.id);
        }
      } catch (error) {
        console.error('Initial auth check error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkInitialAuth();

    // Auth state listener
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      console.log('Auth state changed:', { event, hasSession: !!session });
      setIsLoggedIn(!!session);
    });

    return () => {
      console.log('Cleaning up auth listener');
      authListener.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isLoggedIn) return;

    let isMounted = true;
    let lastUpdateTime = Date.now();
    const MINIMUM_UPDATE_INTERVAL = 60000; 

    const updateActivity = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user.id || !isMounted) return;

        const currentTime = Date.now();
        if (currentTime - lastUpdateTime < MINIMUM_UPDATE_INTERVAL) return;

        await updateLastActive(session.user.id);
        lastUpdateTime = currentTime;
      } catch (error) {
        console.error('Failed to update activity:', error);
      }
    };

    // Set up interval for regular updates
    const interval = setInterval(updateActivity, 5 * 60 * 1000); // Every 5 minutes

    // Set up event listeners for user activity
    const handleActivity = () => {
      updateActivity();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);

    // Initial update
    updateActivity();

    return () => {
      isMounted = false;
      clearInterval(interval);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
    };
  }, [isLoggedIn]);

  console.log('Render state:', { isLoggedIn, isLoading });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/password-change-request" element={<RequestPasswordChange />} />
        <Route path="/email-sent-confirmation" element={<ConfirmEmailForPasswordChange />} />

        {/* Protected routes */}
        {isLoggedIn ? (
          <Route
            element={
              <AnalyticsProvider>
                <Outlet />
              </AnalyticsProvider>
            }
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/search/:query" element={<SearchResults />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/page" element={<ResourcesPageHome />} />
            <Route path="/page/:id" element={<ResourcesPage />} />
            <Route path="/announcements" element={<Announcement />} />
            <Route path="/announcements/archived" element={<ArchivedAnnouncements />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/edit" element={<EditProfile />} />
            <Route path="/training" element={<TrainingHomePage />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/inactive" element={<Inactive />} />
            <Route path="/update-password" element={<ChangePassword />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/partners/:id" element={<PartnerDetails />} />
            <Route path="/recommended-vendors" element={<Vendors />} />
            <Route path="/recommended-vendors/:id" element={<VendorDetails />} />
            <Route path="/recommended-vendors/edit/:id" element={<EditVendor isAdminRoute={false} />} />
            <Route path="/admin/announcements/edit/:id" element={<EditAnnouncement />} />
            <Route path="/staff-admin/*" element={<StaffAndAdminPage />} />
            <Route path="/admin/edit-partner/:id" element={<EditPartner />} />
            <Route path="/admin/edit-vendor/:id" element={<EditVendor isAdminRoute={true} />} />
            <Route path="/user/create-vendor/create" element={<CreateVendor />} />
            <Route path="/admin/create-partner/create" element={<CreatePartner />} />
            <Route path="/users/edit/:id" element={<EditUser />} />
            <Route path="/marketing-center" element={<MarketingCenterMain />} />
            <Route path="/test/modal" element={<ModalTest />} />
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}
      </Routes>

      {isLoggedIn && (
        <>
          <ChatbotBubble isOpen={isChatOpen} setIsOpen={setIsChatOpen} />
          <WeeklyAnnouncementHandler />
        </>
      )}
    </div>
  );
}

export default App;
