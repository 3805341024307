import React from 'react';

interface DonutChartProps {
  total: number;
  active: number;
  label: string;
  activeLabel: string;
  inactiveLabel: string;
}

/**
 * Reusable donut chart component for displaying proportional data
 * @param total - Total number of items
 * @param active - Number of active items
 * @param label - Center label (usually total)
 * @param activeLabel - Label for active portion
 * @param inactiveLabel - Label for inactive portion
 */
export const DonutChart: React.FC<DonutChartProps> = ({
  total,
  active,
  label,
  activeLabel,
  inactiveLabel,
}) => {
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const activePercentage = (active / total) * 100;
  const strokeDasharray = `${(activePercentage * circumference) / 100} ${circumference}`;

  return (
    <div className="flex flex-col items-center w-full max-w-sm mx-auto">
      {/* Chart Container */}
      <div className="relative w-full aspect-square max-w-[200px] sm:max-w-[250px]">
        {/* SVG Donut Chart */}
        <svg 
          className="w-full h-full transform -rotate-90" 
          viewBox="0 0 120 120"
          aria-label="Analytics donut chart"
        >
          {/* Background circle */}
          <circle
            cx="60"
            cy="60"
            r={radius}
            className="fill-none stroke-purple-100"
            strokeWidth="12"
          />
          {/* Active users circle */}
          <circle
            cx="60"
            cy="60"
            r={radius}
            className="fill-none stroke-purple-500"
            strokeWidth="12"
            strokeDasharray={strokeDasharray}
          />
        </svg>
        
        {/* Center Text */}
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
          <span className="text-2xl sm:text-3xl md:text-4xl font-semibold">{label}</span>
          <span className="text-sm text-gray-500">Total Users</span>
        </div>
      </div>
      
      {/* Legend - Moved below the chart */}
      <div className="mt-6 flex flex-col sm:flex-row justify-center gap-4 w-full">
        <div className="flex items-center justify-center">
          <div className="w-3 h-3 rounded-full bg-purple-500 mr-2" />
          <span className="text-sm text-gray-600">{activeLabel}</span>
        </div>
        <div className="flex items-center justify-center">
          <div className="w-3 h-3 rounded-full bg-purple-100 mr-2" />
          <span className="text-sm text-gray-600">{inactiveLabel}</span>
        </div>
      </div>
    </div>
  );
};