import { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import * as images from "../../assets";
import { SidebarLink, ProfileSection } from "./components/index";
import { useUser, useProfile, useAdmin, useStaff } from './hooks';
import supabaseConnect from "../../utils/supabaseConnect";
import StockTicker from '../StockTicker';
import ProfileSkeleton from "../Loading/ProfileSkeleton";
import { useState as useProfileDropdownState } from 'react';
import { FaSearch, FaShoppingBag, FaPalette } from 'react-icons/fa';
import { IconType } from 'react-icons'; // Add this import
import NotificationMenu from './components/NotificationMenu';
import { Notification } from './Services/NotificationService'; // Add this import

/**
 * Sidebar component for the application.
 * 
 * This component renders the main navigation sidebar, including user profile information,
 * navigation links, and toggle functionality for collapsing/expanding the sidebar.
 * 
 * @component
 * @example
 * <Sidebar />
 * 
 * @remarks
 * If this component is missing, the entire navigation structure of the application would break.
 * Users would not be able to navigate between different sections of the app or access their profile information.
 */
type DatabaseNotification = {
  id: string;
  title: string;
  message: string;
  category: string;
  read: boolean;
  created_at: string;
};

const fetchNotifications = async (userId: string) => {
  const supabase = supabaseConnect();
  const { data, error } = await supabase
    .from('notifications')
    .select('*')
    .eq('profile_id', userId)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data as DatabaseNotification[];
};

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [link, setLink] = useState("");
  const [hamburgerState, setHamburgerState] = useState(true);
  const [resourcesExpanded, setResourcesExpanded] = useState(false);
  const [sideNavState, setSideNavState] = useState<string | null>(() => {
    const savedState = localStorage.getItem("realCommunitySideNav");
    return savedState || "full"; 
  });

  const { user } = useUser();
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const { profile } = useProfile(user?.id);
  const { isAdmin } = useAdmin(user?.id);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useProfileDropdownState(false);
  const { isStaff } = useStaff(user?.id);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const notificationButtonRef = useRef<HTMLDivElement>(null);
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    if (!user?.id) return;

    const supabase = supabaseConnect();

    // Initial fetch of notifications
    const loadNotifications = async () => {
      try {
        const notificationData = await fetchNotifications(user.id);
        setNotifications(notificationData);
        const unreadCount = notificationData.filter(notification => !notification.read).length;
        setUnreadNotifications(unreadCount);
      } catch (error) {
        console.error('Error loading notifications:', error);
      }
    };

    // Set up real-time subscription
    const subscription = supabase
      .channel('notifications-channel')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'notifications',
          filter: `profile_id=eq.${user.id}`,
        },
        () => {
          // Refresh notifications when there are changes
          loadNotifications();
        }
      )
      .subscribe();

    // Initial fetch
    loadNotifications();

    // Cleanup subscription
    return () => {
      subscription.unsubscribe();
    };
  }, [user?.id]);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      notificationButtonRef.current && 
      !notificationButtonRef.current.contains(event.target as Node)
    ) {
      setIsNotificationMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (profile) setIsProfileLoading(false);
  }, [profile]);

  

  useEffect(() => {
    const path = location.pathname;
    setLink(path);
    
    if (['/page', '/partners', '/recommended-vendors'].includes(path)) {
      setResourcesExpanded(true);
    }
  }, [location]);

  /**
   * Handles clicking on a sidebar link.
   * 
   * @param {string | React.MouseEvent<HTMLAnchorElement>} pathOrEvent - The path or click event.
   * 
   * @remarks
   * If this function is missing, users won't be able to navigate between different pages of the application.
   * The active link state and resources expansion would also not update correctly.
   */
  const handleLinkClick = useCallback((pathOrEvent: string | React.MouseEvent<HTMLAnchorElement>) => {
    const path = typeof pathOrEvent === 'string' ? pathOrEvent : (pathOrEvent.currentTarget as HTMLAnchorElement).pathname;
    if (path === "/stock-ticker") {
      window.open("https://www.google.com/search?q=reax+price&oq=reax&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgcIARAAGIAEMgYIAhBFGDkyBggDEEUYQDIGCAQQRRg7MgYIBRBFGDsyBggGEEUYOzIGCAcQRRg80gEIMTI3MWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8", "_blank");
    } else if (path === "/feedback") {
      window.open("https://forms.gle/bq8TiG2bKCFcgp1x8", "_blank");
    } else {
      setLink(path);
      navigate(path);
      if (['/page', '/partners', '/recommended-vendors'].includes(path)) {
        setResourcesExpanded(true);
      }
      
    }
  }, [navigate]);

  /**
   * Toggles the expansion state of the Resources section.
   * 
   * @remarks
   * If this function is missing, users won't be able to expand/collapse the Resources section,
   * limiting access to important resource pages.
   */
  const toggleResources = () => setResourcesExpanded(!resourcesExpanded);

  /**
   * Toggles the sidebar between full and collapsed states.
   * 
   * @remarks
   * If this function is missing, users won't be able to collapse or expand the sidebar,
   * which could impact the user experience, especially on smaller screens.
   */
  const toggleSideNav = useCallback(() => {
    const newState = sideNavState === "full" ? "collapsed" : "full";
    localStorage.setItem("realCommunitySideNav", newState);
    setSideNavState(newState);
    setHamburgerState(true);
  }, [sideNavState]);

  /**
   * Custom hook to handle clicks outside the sidebar.
   * 
   * @param {React.RefObject<HTMLElement>} ref - Reference to the sidebar element.
   * 
   * @remarks
   * If this hook is missing, the sidebar won't automatically close when clicking outside of it on mobile devices,
   * potentially obscuring content and degrading the mobile user experience.
   */
  const useOutsideClicker = (ref: React.RefObject<HTMLElement>) => {
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setHamburgerState(true);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideClicker(wrapperRef);

  /**
   * Handles the hamburger menu icon click on mobile devices.
   * 
   * @remarks
   * If this function is missing, mobile users won't be able to open the sidebar,
   * losing access to navigation and important features of the application.
   */
  const handleChevron = () => {
    setHamburgerState(!hamburgerState);
    setSideNavState("full");
  };

  /**
   * Handles user sign out.
   * 
   * @remarks
   * If this function is missing, users won't be able to sign out of the application,
   * which could lead to security issues and prevent proper user session management.
   */
  const handleSignOut = async () => {
    const { error } = await supabaseConnect().auth.signOut();
    if (error) return console.log(error);
    navigate("/login");
  };

  /**
   * Navigates to the user's profile page.
   * 
   * @remarks
   * If this function is missing, users won't be able to access their profile page,
   * preventing them from viewing or editing their personal information.
   */
  const handleProfileClick = () => {
    navigate("/profile");
  };

  /**
   * Toggles the profile dropdown.
   */
  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  };

  /**
   * Handles the admin/staff click.
   */
  const handleAdminStaffClick = () => {
    navigate("/staff-admin/dashboard");
  };


  /**
   * Renders the appropriate sidebar content based on the current state.
   * 
   * @returns {JSX.Element} The sidebar content JSX.
   * 
   * @remarks
   * If this function is missing, the sidebar won't render correctly in either its full or collapsed state,
   * breaking the entire navigation structure of the application.
   */
  const renderSidebarContent = () => {
    if (sideNavState === "collapsed") {
      return (
        <div id="mini-inner-sidenav" className="w-16 flex flex-col flex-1 transition-all duration-300 ease-in-out">
          <div className="w-full flex flex-col flex-1 items-start justify-start gap-8 text-left text-base">
            <div className="bg-gray-800 w-full flex flex-col items-center justify-center box-border py-3 ">
              <img
                src={images.mini_logo}
                alt="mini logo for the collapsed sidenav"
                width="15"
              />
            </div>
            <div className="w-full  flex flex-row items-start justify-start py-0  box-border">
              <div className="flex-1  flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-center gap-[24px]">
                  <div className="self-stretch flex flex-row items-center justify-center tooltip">
                    <a
                      href="/"
                      onClick={(e) => handleLinkClick(e)}
                      title="Dashboard"
                      className={`${
                        link === "Dashboard" || "" ? "" : "opacity-[0.5]"
                      } gap-[8px] self-stretch flex flex-row items-center justify-center cursor-pointer z-10`}
                    >
                      <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src={images.dashboard}
                      />
                    </a>
                  </div>
                  <div
                    className={`${
                      link === "Resource Pages" ? "" : "opacity-[0.5]"
                    } self-stretch flex flex-row items-center justify-center cursor-pointer z-10`}
                  >
                    <a
                      href="/page"
                      title="Resources"
                      className="self-stretch flex flex-row items-center justify-center"
                    >
                      <div className="flex flex-row items-center justify-start gap-[8px]">
                        <img
                          className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                          alt=""
                          src={images.folder}
                        />
                      </div>
                    </a>
                  </div>
                  <div
                    className={`${
                      link === "Training Pages" ? "" : "opacity-[0.5]"
                    } self-stretch flex flex-row items-center justify-center cursor-pointer z-10`}
                  >
                    <a
                      title="Training/Coaching"
                      href="/training"
                      className="flex flex-row items-center justify-center gap-[8px]"
                    >
                      <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src={images.training}
                      />
                    </a>
                  </div>
                  <div className="self-stretch flex flex-row items-center justify-center tooltip">
                    <a
                      href="/marketing-center"
                      onClick={(e) => handleLinkClick(e)}
                      title="Marketing Center"
                      className={`${
                        link === "/marketing-center" ? "" : "opacity-[0.5]"
                      } gap-[8px] self-stretch flex flex-row items-center justify-center cursor-pointer z-10`}
                    >
                      <FaPalette className="w-[18px] h-[18px]" />
                    </a>
                  </div>
                  <div className="self-stretch flex flex-row items-center justify-center tooltip">
                    <a
                      href="https://shop.realcommunity.com/us/en/stores/community-45920/S531562762"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Swag"
                      className={`${
                        link === "Swag" ? "" : "opacity-[0.5]"
                      } gap-[8px] self-stretch flex flex-row items-center justify-center cursor-pointer z-10`}
                    >
                      <FaShoppingBag className="w-[18px] h-[18px]" />
                    </a>
                  </div>
                  <div className="self-stretch flex flex-row items-center justify-center tooltip">
                    <a
                      href="/search"
                      onClick={(e) => handleLinkClick(e)}
                      title="Search"
                      className={`${
                        link === "/search" ? "" : "opacity-[0.5]"
                      } gap-[8px] self-stretch flex flex-row items-center justify-center cursor-pointer z-10`}
                    >
                      <FaSearch className="w-[18px] h-[18px]" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            {isProfileLoading ? (
              <ProfileSkeleton />
            ) : profile && (
              <div className=" flex flex-row items-center justify-start gap-[.8rem] text-left text-base">
                <img
                  className="rounded-[50%] w-10 h-10 object-cover"
                  alt=""
                  src={
                    profile?.profile_image_url ||
                    "https://via.placeholder.com/150"
                  }
                />
                <div className="overflow-hidden">
                  <p className="m-0 font-sm word-clip">
                    {profile.first_name && profile.last_name
                      ? `${profile.first_name} ${profile.last_name}`
                      : ""}
                  </p>
                  <p className="m-0 text-sm leading-[16px] word-clip">
                    {profile?.email}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div id="full-inner-sidenav" className="w-full lg:w-64 flex flex-col flex-1 transition-all duration-300 ease-in-out">
          <div className="w-full flex flex-col flex-1 items-start justify-start gap-[32px] text-left text-base">
            <div className="bg-gray-800 w-full flex flex-col items-center justify-center box-border py-3">
              <img
                className="relative w-[180px] h-[4rem] overflow-hidden shrink-0 cursor-pointer z-10"
                alt=""
                src={images.communityLogo}
                onClick={() => navigate("/")}
              />
            </div>
            <div className="w-full  flex flex-row items-start justify-start py-0 pr-5 pl-[30px] box-border">
              <div className="flex-1  flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-center gap-[24px]">
                  <SidebarLink
                    icon={images.dashboard}
                    text="Dashboard"
                    isActive={link === "/"}
                    onClick={() => handleLinkClick("/")}
                  />
                  <div
                    className={`${
                      ['/page', '/partners', '/recommended-vendors'].includes(link) ? "text-white" : "text-gray-400"
                    } self-stretch flex flex-col items-start justify-between cursor-pointer z-10 transition-colors duration-200 hover:text-communityBlue`}
                  >
                    <div className="self-stretch flex flex-row items-center justify-between" onClick={toggleResources}>
                      <div className="flex flex-row items-center justify-start gap-[8px]">
                        <img
                          className={`relative w-[18px] h-[18px] overflow-hidden shrink-0 ${
                            ['/page', '/partners', '/recommended-vendors'].includes(link) ? "" : "opacity-50"
                          }`}
                          alt=""
                          src={images.folder}
                        />
                        <div className="relative font-medium inline-block shrink-0">
                          Resources
                        </div>
                      </div>
                      <img 
                        className={`w-4 h-4 transition-transform duration-200 ${resourcesExpanded ? 'rotate-180' : ''} ${
                          ['/page', '/partners', '/recommended-vendors'].includes(link) ? "" : "opacity-50"
                        }`}
                        src={images.chevron_right} 
                        alt="expand"
                      />
                    </div>
                    {resourcesExpanded && (
                      <div className="pl-10 mt-2 flex flex-col gap-2">
                        <div 
                          className={`cursor-pointer ${link === '/page' ? 'text-white font-bold' : 'text-gray-400 hover:text-communityBlue'}`} 
                          onClick={() => handleLinkClick("/page")}
                        >
                          All Resources
                        </div>
                        <div 
                          className={`cursor-pointer ${link === '/partners' ? 'text-white font-bold' : 'text-gray-400 hover:text-communityBlue'}`} 
                          onClick={() => handleLinkClick("/partners")}
                        >
                          Community Partners
                        </div>
                        <div 
                          className={`cursor-pointer ${link === '/recommended-vendors' ? 'text-white font-bold' : 'text-gray-400 hover:text-communityBlue'}`} 
                          onClick={() => handleLinkClick("/recommended-vendors")}
                        >
                          Recommended Vendors
                        </div>
                      </div>
                    )}
                  </div>
                  <SidebarLink
                    icon={images.training}
                    text="Training/Coaching"
                    isActive={link === "/training"}
                    onClick={() => handleLinkClick("/training")}
                  />
                  <SidebarLink
                    icon={FaPalette}
                    text="Marketing Center"
                    isActive={link === "/marketing-center"}
                    onClick={() => handleLinkClick("/marketing-center")}
                  />
                  <SidebarLink
                    icon={FaShoppingBag}
                    text="Swag"
                    isActive={false}
                    onClick={() => window.open("https://shop.realcommunity.com/us/en/stores/community-45920/S531562762", "_blank")}
                  />
                  <SidebarLink
                    icon={images.feedback}
                    text="Feedback"
                    isActive={link === "/feedback"}
                    onClick={() => handleLinkClick("/feedback")}
                  />
                  <SidebarLink
                    icon={FaSearch}
                    text="Search"
                    isActive={link === "/search"}
                    onClick={() => handleLinkClick("/search")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-auto pb-4">
            <div className="w-full lg:w-[15rem] mb-4 cursor-pointer ml-1" onClick={() => handleLinkClick("/stock-ticker")}>
              <StockTicker />
            </div>

            {isProfileLoading ? (
              <ProfileSkeleton />
            ) : (
              <ProfileSection 
                profile={profile} 
                onSignOut={handleSignOut} 
                onProfileClick={handleProfileClick}
                isDropdownOpen={isProfileDropdownOpen}
                toggleDropdown={toggleProfileDropdown}
                isAdmin={isAdmin}
                onAdminStaffClick={handleAdminStaffClick}
                isStaff={isStaff}
              />
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div className={`${hamburgerState ? "relative" : "absolute z-20 max-sm:w-full"}`}>
      <div className="fixed top-0 left-0 right-0 h-12 bg-[#18181b] text-white flex items-center px-4 z-50">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            <div
              className="lg:hidden space-y-1 cursor-pointer"
              onClick={() => handleChevron()}
            >
              <span className="block h-0.5 w-5 bg-white"></span>
              <span className="block h-0.5 w-5 bg-white"></span>
              <span className="block h-0.5 w-5 bg-white"></span>
            </div>
          </div>

          {/* <div className="hidden md:flex flex-1 max-w-xl mx-4">
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Search"
                className="w-full bg-[#1f1f23] border border-transparent hover:border-[#464649] text-white px-4 py-1 rounded-md focus:outline-none focus:border-communityBlue"
              />
              <button className="absolute right-2 top-1/2 transform -translate-y-1/2">
                <FaSearch className="text-gray-400" />
              </button>
            </div>
          </div> */}

          <div className="flex items-center space-x-4">
            <div className="relative">
              <div 
                ref={notificationButtonRef}
                className="text-gray-400 hover:text-white cursor-pointer relative p-2 transition-colors duration-200"
                onClick={() => setIsNotificationMenuOpen(!isNotificationMenuOpen)}
                role="button"
                tabIndex={0}
                aria-label="Notifications"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setIsNotificationMenuOpen(!isNotificationMenuOpen);
                  }
                }}
              >
                <svg 
                  className="w-5 h-5" 
                  fill="none" 
                  strokeWidth="2"
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" 
                  />
                </svg>
                {unreadNotifications > 0 && (
                  <span className="absolute -top-0.5 -right-0.5 bg-communityBlue text-white text-xs font-medium rounded-full min-w-[18px] h-[18px] flex items-center justify-center px-1">
                    {unreadNotifications}
                  </span>
                )}
              </div>
              <NotificationMenu 
                isOpen={isNotificationMenuOpen}
                notifications={notifications.map(notification => ({
                  id: notification.id,
                  title: notification.title,
                  timestamp: new Date(notification.created_at).toLocaleString(),
                  category: notification.category,
                  read: notification.read
                }))}
                onClose={() => setIsNotificationMenuOpen(false)}
                onUnreadCountChange={setUnreadNotifications}
              />
            </div>
          </div>
        </div>
      </div>

      <aside
        className={`relative ${
          hamburgerState ? "lg:flex hidden" : "flex absolute z-30"
        } flex-row justify-between text-center text-xs h-[calc(100vh-48px)] bg-black text-white transition-all duration-300 ease-in-out w-full overflow-y-auto overflow-x-hidden mt-12`}
        ref={wrapperRef}
      >
        <div
          onClick={toggleSideNav}
          role="button"
          className={`absolute top-[50px] right-0 sm:right-[-17px] bg-communityBlue sm:rounded-tr-[4px] sm:rounded-br-[4px] max-sm:rounded-tl-[4px] max-sm:rounded-bl-[4px] flex px-[1px] py-1 cursor-pointer transition-transform duration-300 ${
            sideNavState === "full" ? "transform translate-x-0" : "transform -translate-x-[17px]"
          }`}
        >
          <img
            id="chevron"
            src={images.chevron_right}
            width="15"
            height="15"
            alt=""
            className={`transition-transform duration-300 ${
              sideNavState === "full" ? "transform rotate-0" : "transform rotate-180"
            }`}
          />
        </div>

        {renderSidebarContent()}
      </aside>

      <div
        id={`${hamburgerState === false ? "show-hidden-bg" : "hidden-bg"}`}
      ></div>
    </div>
  );
};

export default Sidebar;
