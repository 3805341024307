import React, { useState } from 'react';
import { SurveyQuestion } from './MonthlySurveyModal';

interface SurveyContentProps {
  questions: SurveyQuestion[];
  onSubmit: (responses: Record<string, string>) => void;
  onCancel: () => void;
}

/**
 * SurveyContent component
 * 
 * Renders a survey form with different types of questions (text, radio, rating).
 * Handles validation, submission, and error states for survey responses.
 * 
 * @param {SurveyQuestion[]} questions - Array of survey questions to display
 * @param {(responses: Record<string, string>) => void} onSubmit - Callback function when survey is submitted
 * @param {() => void} onCancel - Callback function when survey is cancelled
 * 
 * @example
 * <SurveyContent
 *   questions={surveyQuestions}
 *   onSubmit={handleSurveySubmit}
 *   onCancel={handleSurveyClose}
 * />
 */
const SurveyContent: React.FC<SurveyContentProps> = ({
  questions,
  onSubmit,
  onCancel,
}) => {
  const [responses, setResponses] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    // Reset errors before submission
    const newErrors: Record<string, string> = {};
    questions.forEach(question => {
      if (question.required && !responses[question.id]) {
        newErrors[question.id] = 'This question is required.';
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return; 
    }

    setIsSubmitting(true);
    await onSubmit(responses);
    setIsSubmitting(false);
  };

  const handleResponse = (questionId: string, value: string) => {
    setResponses(prev => ({
      ...prev,
      [questionId]: value
    }));
  };

  const renderQuestion = (question: SurveyQuestion) => {
    switch (question.type) {
      case 'text':
        return (
          <textarea
            className="w-[500px] h-24 p-4 bg-[#2a475e] text-white rounded-lg 
              border border-gray-600 focus:border-[#66c0f4] outline-none resize-none
              transition-colors duration-200 hover:border-[#66c0f4]/50"
            value={responses[question.id] || ''}
            onChange={(e) => handleResponse(question.id, e.target.value)}
            placeholder="Enter your response..."
          />
        );
      case 'radio':
        return (
          <div className="space-y-2">
            {question.options?.map((option, index) => (
              <label key={index} className="flex items-center space-x-2">
                <input
                  type="radio"
                  name={question.id}
                  value={option}
                  checked={responses[question.id] === option}
                  onChange={(e) => handleResponse(question.id, e.target.value)}
                  className="text-blue-500"
                />
                <span className="text-white">{option}</span>
              </label>
            ))}
          </div>
        );
      case 'rating':
        const ratingOptions = [
          { value: '1', label: '1' },
          { value: '2', label: '2' },
          { value: '3', label: '3' },
        ];
        return (
          <div className="flex items-center w-full">
            <span className="text-4xl" role="img" aria-label="sad emoji">😢</span>
            <div className="flex gap-8 ml-8">
              {ratingOptions.map((option) => (
                <div
                  key={option.value}
                  onClick={() => handleResponse(question.id, option.value)}
                  className={`
                    w-14 h-14 flex items-center justify-center
                    cursor-pointer transition-all duration-200
                    relative group
                  `}
                  role="button"
                  tabIndex={0}
                  aria-label={`Rating ${option.label}`}
                >
                  <div className={`
                    absolute w-10 h-10 rounded-full
                    transition-all duration-200
                    ${responses[question.id] === option.value 
                      ? 'bg-[#66c0f4] transform scale-105' 
                      : 'bg-transparent group-hover:bg-[#66c0f4]/20'
                    }
                    border-2 
                    ${responses[question.id] === option.value 
                      ? 'border-[#66c0f4]' 
                      : 'border-gray-600 group-hover:border-[#66c0f4]'
                    }
                  `} />
                  <span className={`
                    text-base z-10
                    ${responses[question.id] === option.value ? 'text-black font-medium' : 'text-white'}
                  `}>
                    {option.label}
                  </span>
                </div>
              ))}
            </div>
            <span className="text-4xl ml-8" role="img" aria-label="happy emoji">😊</span>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div 
      data-testid="survey-content"
      className="bg-[#1b2838] w-full md:w-3/4 xl:w-2/3 mx-auto rounded-lg shadow-2xl mb-8 ring-1 ring-white/10"
    >
      <div className="p-6">
        <h2 className="text-2xl font-bold text-white mb-6">Monthly Feedback Survey</h2>
        <div className="space-y-8">
          {questions.sort((a, b) => a.order - b.order).map((question) => (
            <div key={question.id} className="space-y-2">
                {errors[question.id] && (
                <p className="text-red-500 text-sm">{errors[question.id]}</p>
              )}
              <label className="block text-lg text-white">
                {question.question}
                {question.required && <span className="text-red-500 ml-1">*</span>}
              </label>
              {renderQuestion(question)}
              
            </div>
          ))}
        </div>
        <div className="mt-8 flex justify-end space-x-4">
          <div
            onClick={handleSubmit}
            className="px-4 py-2 text-white rounded cursor-pointer transition-colors
              border-2 border-gray-600 hover:border-[#66c0f4] hover:bg-[#66c0f4]
              disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyContent; 