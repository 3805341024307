import React, { useEffect, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Box, useTheme } from '@mui/material';
import supabaseConnect from '../../../../../utils/supabaseConnect';

const supabase = supabaseConnect();

// Colorado timezone (Mountain Time)
const TIMEZONE = 'America/Denver';

interface TimelineData {
  hour: number;
  count: number;
}

/**
 * ActivityTimelineChart Component
 * Displays page view activity over time using MUI's LineChart
 * Shows hourly page view counts in a responsive line graph
 * Data is displayed in Mountain Time (Colorado timezone)
 */
export const ActivityTimelineChart: React.FC = () => {
  const [timelineData, setTimelineData] = useState<TimelineData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const fetchTimelineData = async () => {
      try {
        // Get start of day in Colorado timezone
        const today = new Date();
        const coloradoDate = new Date(today.toLocaleString('en-US', { timeZone: TIMEZONE }));
        coloradoDate.setHours(0, 0, 0, 0);
        
        // Convert to UTC for database query
        const startOfDay = new Date(coloradoDate.toLocaleString('en-US', { timeZone: 'UTC' }));
        const endOfDay = new Date(startOfDay);
        endOfDay.setHours(startOfDay.getHours() + 24);
        
        const { data, error } = await supabase
          .from('page_views')
          .select('created_at')
          .gte('created_at', startOfDay.toISOString())
          .lt('created_at', endOfDay.toISOString());

        if (error) throw error;

        // Initialize array with 24 hours
        const hourlyData = Array.from({ length: 24 }, (_, i) => ({
          hour: i,
          count: 0
        }));

        // Count page views per hour in Colorado timezone
        data?.forEach(view => {
          const viewDate = new Date(view.created_at);
          const coloradoHour = new Date(viewDate.toLocaleString('en-US', { timeZone: TIMEZONE })).getHours();
          hourlyData[coloradoHour].count++;
        });

        setTimelineData(hourlyData);
      } catch (error) {
        console.error('Failed to fetch timeline data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTimelineData();
  }, []);

  if (isLoading) {
    return (
      <Box className="bg-gray-800 rounded-lg p-6 shadow-lg">
        <h2 className="text-xl font-semibold text-white mb-4">Daily Page Views Timeline</h2>
        <div className="h-[400px] flex items-center justify-center">
          <span className="text-gray-400">Loading data...</span>
        </div>
      </Box>
    );
  }

  const xLabels = timelineData.map(item => `${item.hour % 12 || 12} ${item.hour >= 12 ? 'PM' : 'AM'}`);
  const yValues = timelineData.map(item => item.count);

  return (
    <Box className="bg-white rounded-lg p-6 shadow-lg">
      <h2 className="text-xl font-semibold text-black mb-4">Daily Page Views Timeline</h2>
      <Box className="h-[400px]">
        <LineChart
          xAxis={[{
            data: xLabels,
            scaleType: 'band',
            tickLabelStyle: {
              fill: theme.palette.grey[400],
            },
          }]}
          yAxis={[{
            tickLabelStyle: {
              fill: theme.palette.grey[400],
            },
          }]}
          series={[
            {
              data: yValues,
              area: true,
              color: '#3b82f6', // Tailwind blue-500
              curve: 'natural',
            },
          ]}
          sx={{
            '.MuiLineElement-root': {
              strokeWidth: 2,
            },
            '.MuiAreaElement-root': {
              fillOpacity: 0.1,
            },
          }}
        />
      </Box>
    </Box>
  );
};