import { createContext } from 'react';
import { PageViewData, LinkClickData } from '../types/analytics.types';

interface AnalyticsContextType {
  recordPageView: (pageData: PageViewData) => Promise<void>;
  recordLinkClick: (linkData: LinkClickData) => Promise<void>;
  sessionId: string | null;
}

export const AnalyticsContext = createContext<AnalyticsContextType>({
  recordPageView: async () => {},
  recordLinkClick: async () => {},
  sessionId: null,
}); 