import supabaseClient from '../../../utils/supabaseConnect';

const supabase = supabaseClient();

/**
 * Represents a notification object in the system
 */
export interface Notification {
  id: string;
  title: string;
  message?: string;
  category: string;
  read: boolean;
  created_at: string;
}

/**
 * Fetches notifications from Supabase for the current user
 * @returns Array of notifications sorted by creation date
 */
export const fetchNotifications = async (): Promise<Notification[]> => {
  const { data: { user } } = await supabase.auth.getUser();
  
  if (!user) throw new Error('No authenticated user');

  const { data: notifications, error } = await supabase
    .from('notifications')
    .select('*')
    .eq('profile_id', user.id)
    .order('created_at', { ascending: false });

  if (error) {
    console.error('Error fetching notifications:', error);
    throw new Error('Failed to fetch notifications');
  }

  return notifications || [];
};

/**
 * Marks a single notification as read in Supabase
 * @param id - The ID of the notification to mark as read
 */
export const markNotificationAsRead = async (id: string): Promise<void> => {
  const { data: { user } } = await supabase.auth.getUser();
  
  if (!user) throw new Error('No authenticated user');

  const { error } = await supabase
    .from('notifications')
    .update({ read: true })
    .eq('id', id)
    .eq('profile_id', user.id);
  
  if (error) {
    console.error('Error marking notification as read:', error);
    throw new Error('Failed to mark notification as read');
  }
};

/**
 * Marks all notifications as read in Supabase for the current user
 */
export const markAllNotificationsAsRead = async (): Promise<void> => {
  const { data: { user } } = await supabase.auth.getUser();
  
  if (!user) throw new Error('No authenticated user');

  const { error } = await supabase
    .from('notifications')
    .update({ read: true })
    .eq('read', false)
    .eq('profile_id', user.id);

  if (error) {
    console.error('Error marking all notifications as read:', error);
    throw new Error('Failed to mark all notifications as read');
  }
};

/**
 * Handles clicking a notification - marks it as read in the database
 * @param notification - The notification object that was clicked
 * @returns The updated notification with read status set to true
 * @throws Error if the operation fails or user is not authenticated
 */
export const handleNotificationClick = async (notification: Notification): Promise<Notification> => {
  const { data: { user } } = await supabase.auth.getUser();

  console.log('notification', notification);
  
  if (!user) throw new Error('No authenticated user');

  // Only update if not already read
  if (!notification.read) {
    const { data: updatedNotification, error } = await supabase
      .from('notifications')
      .update({ read: true })
      .eq('id', notification.id)
      .eq('profile_id', user.id)
      .select()
      .single();
    
    if (error) {
      console.error('Error handling notification click:', error);
      throw new Error('Failed to update notification');
    }

    return updatedNotification;
  }

  return notification;
};
