export interface SearchResult {
  id: string;
  title: string;
  description: string;
  body: string;
  table_name: string;
  // Add any other relevant fields
}

export function orderByRelevance(results: SearchResult[], searchTerm: string): SearchResult[] {
  const searchTerms = removeDiacritics(searchTerm.toLowerCase()).split(' ');

  return results
    .map(result => ({
      ...result,
      relevanceScore: calculateDetailedRelevanceScore(result, searchTerms)
    }))
    .sort((a, b) => b.relevanceScore - a.relevanceScore);
}

function calculateDetailedRelevanceScore(item: SearchResult, searchTerms: string[]): number {
  let score = 0;

  const normalizedTitle = removeDiacritics(item.title.toLowerCase());
  const normalizedDescription = removeDiacritics(item.description.toLowerCase());
  const normalizedBody = removeDiacritics(item.body.toLowerCase());

  // Exact match bonuses
  if (normalizedTitle.includes(searchTerms.join(' '))) score += 50;
  if (normalizedDescription.includes(searchTerms.join(' '))) score += 30;
  if (normalizedBody.includes(searchTerms.join(' '))) score += 20;

  // Individual term matches
  searchTerms.forEach(term => {
    if (term.length > 2) {
      if (normalizedTitle.includes(term)) score += 10;
      if (normalizedDescription.includes(term)) score += 5;
      if (normalizedBody.includes(term)) score += 3;
    }
  });

  // Boost score based on content type
  switch (item.table_name) {
    case 'page':
      score *= 1.2; // 20% boost for pages
      break;
    case 'announcement':
      score *= 1.1; // 10% boost for announcements
      break;
    // Add other content types as needed
  }

  // Normalize score (0-100 range)
  return Math.min(Math.round(score), 100);
}

export function removeDiacritics(str: string): string {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function getTopResults(results: SearchResult[], n: number): SearchResult[] {
  return results.slice(0, n);
}

// Keep the existing calculateRelevanceScore function
export function calculateRelevanceScore(query: string, title: string, description: string): number {
  const normalizedTitle = removeDiacritics(title.toLowerCase());
  const normalizedDescription = removeDiacritics(description.toLowerCase());

  let score = 0;

  // Check for exact matches
  if (normalizedTitle.includes(query)) score += 10;
  if (normalizedDescription.includes(query)) score += 5;

  // Check for partial matches
  const words = query.split(' ');
  words.forEach(word => {
    if (word.length > 2) {
      if (normalizedTitle.includes(word)) score += 3;
      if (normalizedDescription.includes(word)) score += 2;
    }
  });

  return score;
}

/**
 * Calculates a relevance score for a search result based on multiple factors
 * @param {Object} item - The search result item to score
 * @param {string} searchTerm - The search term to match against
 * @returns {number} A relevance score between 0 and 100
 * @breaks Without this, search results wouldn't be properly ranked by relevance
 */
export const newCalculateRelevanceScore = (item: any, searchTerm: string): number => {
  if (!searchTerm) return 0;
  
  // Normalize search terms and content
  const searchTerms = searchTerm.toLowerCase().split(' ').filter(term => term.length > 2);
  const title = (item.title || item.name || '').toLowerCase();
  const description = (item.description || '').toLowerCase();
  const body = (item.body || item.content || item.info || '').toLowerCase();

  let score = 0;

  // Score each search term separately
  searchTerms.forEach(term => {
    // Title matches (highest weight)
    if (title.includes(term)) {
      score += 40; // Base score for title match
      
      // Bonus for match at start of title
      if (title.startsWith(term)) {
        score += 20;
      }
      
      // Bonus for exact title match
      if (title === term) {
        score += 30;
      }
    }

    // Description matches (medium weight)
    if (description.includes(term)) {
      score += 20;
      
      // Bonus for match at start of description
      if (description.startsWith(term)) {
        score += 10;
      }
    }

    // Body matches (lower weight)
    if (body.includes(term)) {
      score += 10;
    }
  });

  // Bonus for matching all search terms
  const matchesAllTerms = searchTerms.every(term => 
    title.includes(term) || description.includes(term) || body.includes(term)
  );
  if (matchesAllTerms) {
    score += 15;
  }

  // Penalty for very long content (favors more focused results)
  const contentLength = (title + description + body).length;
  const lengthPenalty = Math.min(10, Math.floor(contentLength / 1000));
  score -= lengthPenalty;

  // Normalize score to 0-100 range
  return Math.min(100, Math.max(0, score));
};

/**
 * Helper function to check if a string starts with any of the search terms
 * @param {string} text - The text to check
 * @param {string[]} searchTerms - Array of search terms
 * @returns {boolean} True if the text starts with any search term
 */
const startsWithAnyTerm = (text: string, searchTerms: string[]): boolean => {
  return searchTerms.some(term => text.startsWith(term));
};
