import React, { useEffect, useState } from 'react';
import supabaseConnect from '../../../../../utils/supabaseConnect';

interface UserActivity {
  user_id: string;
  first_name: string;
  last_name: string;
  profile_image_url: string;
  activity_count: number;
}

const AVATAR_COLORS = [
  'bg-blue-500',
  'bg-green-500',
  'bg-red-500',
  'bg-yellow-500',
  'bg-purple-500',
  'bg-pink-500',
  'bg-indigo-500',
  'bg-teal-500',
];

const getInitialsColor = (userId: string): string => {
  const colorIndex = parseInt(userId, 16) % AVATAR_COLORS.length;
  return AVATAR_COLORS[colorIndex];
};

export const UserActivityChart: React.FC = () => {
  const [userActivities, setUserActivities] = useState<UserActivity[]>([]);
  const supabase = supabaseConnect();

  useEffect(() => {
    const fetchUserActivity = async () => {
      // Get user sessions count grouped by user
      const { data: activityData, error } = await supabase
        .from('user_sessions')
        .select(`
          user_id,
          profiles!inner(
            first_name,
            last_name,
            profile_image_url
          )
        `)
        .not('user_id', 'is', null);

      if (error) {
        console.error('Error fetching user activity:', error);
        return;
      }

      // Process and aggregate the data
      const activityCounts = activityData.reduce((acc: { [key: string]: UserActivity }, session: any) => {
        const userId = session.user_id;
        if (!acc[userId]) {
          acc[userId] = {
            user_id: userId,
            first_name: session.profiles.first_name,
            last_name: session.profiles.last_name,
            profile_image_url: session.profiles.profile_image_url,
            activity_count: 0
          };
        }
        acc[userId].activity_count++;
        return acc;
      }, {});

      // Convert to array and sort by activity count
      const sortedActivities = Object.values(activityCounts)
        .sort((a, b) => b.activity_count - a.activity_count)
        .slice(0, 10); // Get top 10 users

      setUserActivities(sortedActivities);
    };

    fetchUserActivity();
  }, []);

  const maxActivity = Math.max(...userActivities.map(u => u.activity_count), 1);
  const chartHeight = 300;
  
  // Calculate a nice round maximum for the y-axis
  const calculateYAxisMax = (maxValue: number) => {
    const power = Math.floor(Math.log10(maxValue));
    const unit = Math.pow(10, power);
    const roundedMax = Math.ceil(maxValue / unit) * unit;
    return roundedMax;
  };

  const yAxisMax = calculateYAxisMax(maxActivity);
  
  // Generate labels with appropriate suffixes (k, M, etc.)
  const formatNumber = (num: number) => {
    if (num >= 1000) {
      return (num / 1000).toString() + 'k';
    }
    return num.toString();
  };

  const yAxisLabels = [...Array(6)].map((_, i) => {
    const value = Math.round((yAxisMax * (5 - i)) / 5);
    return formatNumber(value);
  });

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-xl font-semibold mb-8">Total Activity by User</h2>
      
      <div className="relative h-[400px]">
        <div className="flex h-full">
          {/* Y-axis labels */}
          <div className="w-12 flex flex-col justify-between h-[300px] pr-2">
            {yAxisLabels.map((label, i) => (
              <span key={i} className="text-xs text-gray-500 text-right">
                {label}
              </span>
            ))}
          </div>

          {/* Chart area */}
          <div className="flex-1 relative">
            {/* Horizontal grid lines */}
            <div className="absolute inset-0 flex flex-col justify-between h-[300px]">
              {[...Array(6)].map((_, i) => (
                <div
                  key={i}
                  className={`relative w-full ${i % 2 === 0 ? 'bg-gray-50' : ''}`}
                  style={{ height: `${100 / 6}%` }}
                >
                  <div className="absolute top-0 left-0 right-0 border-t border-gray-200 w-full" />
                </div>
              ))}
            </div>

            {/* Bars container - modified spacing and alignment */}
            <div className="absolute inset-0 flex items-end pb-12 z-10" style={{ gap: '12px' }}>
              {userActivities.map((user) => {
                const barHeight = (user.activity_count / yAxisMax) * chartHeight;
                
                return (
                  <div key={user.user_id} className="flex flex-col items-center flex-1 min-w-0 group">
                    {/* Activity count label */}
                    <div className="text-xs text-gray-600 mb-1">
                      {user.activity_count}
                    </div>
                    
                    {/* Bar - modified width and colors */}
                    <div 
                      className="w-full max-w-[48px] bg-[#4F46E5] rounded-t transition-all duration-200 hover:bg-[#4338CA]"
                      style={{ height: `${Math.max(barHeight, 2)}px` }}
                    />
                    
                    {/* User info - modified sizing */}
                    <div className="mt-3 flex flex-col items-center">
                      {user.profile_image_url ? (
                        <div className="w-8 h-8 rounded-full bg-gray-200 overflow-hidden">
                          <img
                            src={user.profile_image_url}
                            alt={`${user.first_name} ${user.last_name}`}
                            className="w-full h-full object-cover"
                          />
                        </div>
                      ) : (
                        <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white text-xs font-medium ${getInitialsColor(user.user_id)}`}>
                          {user.first_name?.[0] || ''}{user.last_name?.[0] || ''}
                        </div>
                      )}
                      <span className="text-xs text-gray-600 mt-1 truncate max-w-[48px]">
                        {user.first_name?.[0] || ''}{user.last_name?.[0] || ''}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Legend section can be removed if not needed */}
    </div>
  );
};