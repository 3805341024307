import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import MainLayoutWrapper from '../../components/Layout/MainLayoutWrapper';
import supabaseConnect from '../../utils/supabaseConnect';
import { sanitizeHtmlContent } from '../../utils/sanitizeHtml';
import StarRating from '../../components/Rating/StarRating';
import RatingModal from '../../components/Rating/RatingModal';
import RatingsOverview from '../../components/Rating/RatingsOverview';

/**
 * Formats a phone number string to XXX-XXX-XXXX format
 * @param phone - The phone number to format
 * @returns The formatted phone number
 * @remarks
 * This function removes all non-numeric characters and adds dashes in the correct positions.
 * If the phone number is invalid or not 10 digits, it returns the original string.
 */
const formatPhoneNumber = (phone: string): string => {
  // Remove all non-numeric characters
  const cleaned = phone.replace(/\D/g, '');
  
  // Check if we have a valid 10-digit number
  if (cleaned.length === 10) {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }
  
  // Return original if not valid
  return phone;
};

const VendorDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [vendor, setVendor] = useState<any>(null);
  const [averageRating, setAverageRating] = useState<number>(0);
  const [ratingCount, setRatingCount] = useState<number>(0);
  const [userRating, setUserRating] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showRatingsOverview, setShowRatingsOverview] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [ratingsData, setRatingsData] = useState<{ average: number, count: number } | null>(null);
  const [ratingsLoading, setRatingsLoading] = useState<boolean>(true);
  const [ratingsError, setRatingsError] = useState<string | null>(null);

  const [selectedRating, setSelectedRating] = useState<number | null>(null);
  const [showCopied, setShowCopied] = useState<boolean>(false);

  useEffect(() => {
    fetchVendorDetails();
    fetchRatings();
  }, [id]);

  useEffect(() => {
    if (ratingsData) {
      setAverageRating(ratingsData.average);
      setRatingCount(ratingsData.count);
    }
  }, [ratingsData]);

  useEffect(() => {
    const checkUserRating = async () => {
      const supabase = supabaseConnect();
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('vendor_ratings')
          .select('rating')
          .eq('vendor_id', id)
          .eq('profile_id', user.id)
          .single();

        if (error) {
          console.error('Error checking user rating:', error);
        } else if (data) {
          setUserRating(data.rating);
        }
      }
    };

    checkUserRating();
  }, [id]);

  useEffect(() => {
    const supabase = supabaseConnect();
    const subscription = supabase
      .channel('vendor_ratings')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'vendor_ratings' }, (payload) => {
        if (payload.new && typeof payload.new === 'object' && 'vendor_id' in payload.new) {
          if (payload.new.vendor_id === id) {
            fetchRatings();
          }
        }
      })
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [id]);

  const fetchVendorDetails = async () => {
    const supabase = supabaseConnect();
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('vendors')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      setVendor(data);
    } catch (error) {
      console.error('Error fetching vendor details:', error);
      setError('Failed to fetch vendor details');
    } finally {
      setLoading(false);
    }
  };

  const fetchRatings = async () => {
    const supabase = supabaseConnect();
    try {
      setRatingsLoading(true);
      const { data, error } = await supabase
        .from('vendor_ratings')
        .select('rating')
        .eq('vendor_id', id);

      if (error) throw error;
      const ratings = data.map(r => r.rating);
      const avg = ratings.length > 0 ? ratings.reduce((a, b) => a + b, 0) / ratings.length : 0;
      setRatingsData({ average: avg, count: ratings.length });
    } catch (error) {
      console.error('Error fetching ratings:', error);
      setRatingsError('Failed to fetch ratings');
    } finally {
      setRatingsLoading(false);
    }
  };

  const handleRatingClick = (rating: number) => {
    if (userRating === null) {
      setSelectedRating(rating);
      setIsModalOpen(true);
    }
  };

  const handleRatingSubmit = async (rating: number, comment: string) => {
    const supabase = supabaseConnect();
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      try {
        const { error } = await supabase
          .from('vendor_ratings')
          .upsert({
            vendor_id: id,
            profile_id: user.id,
            rating,
            comment
          });

        if (error) throw error;
        fetchRatings();
        setUserRating(rating);
        setIsModalOpen(false);
      } catch (error) {
        console.error('Error submitting rating:', error);
        alert('Failed to submit rating. Please try again.');
      }
    }
  };

  const handleEmailClick = (email: string) => {
    if (email && email !== 'N/A') {
      window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`, '_blank');
    }
  };

  const handlePhoneClick = (phone: string) => {
    if (phone && phone !== 'N/A') {
      navigator.clipboard.writeText(phone)
        .then(() => {
          setShowCopied(true);
          setTimeout(() => setShowCopied(false), 2000);
        })
        .catch((err) => {
          console.error('Failed to copy phone number:', err);
        });
    }
  };

  if (loading || ratingsLoading) return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      </MainLayoutWrapper>
    </div>
  );

  if (error || ratingsError) return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="flex items-center justify-center h-full">
          <div className="text-red-500 text-xl">{error || ratingsError}</div>
        </div>
      </MainLayoutWrapper>
    </div>
  );

  if (!vendor) return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="flex items-center justify-center h-full">
          <div className="text-xl">Vendor not found</div>
        </div>
      </MainLayoutWrapper>
    </div>
  );

  const sanitizedInfo = sanitizeHtmlContent(vendor.info);

  console.log(sanitizedInfo);

  return (
    <div className="flex w-full h-screen overflow-hidden text-white bg-[#1d2025]">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5 flex flex-col h-full">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold mb-6 text-center text-white"
              style={{
                WebkitTextStroke: '.3px white',
                textShadow: '0 0 3px rgba(255,255,255,0.5)',
                ...({"textStroke": '1px white'} as React.CSSProperties)
              }}>
            Recommended Vendor
          </h1>
          <div className="relative flex-grow overflow-hidden">
            <div className="absolute inset-0 bg-black rounded-lg shadow-lg border border-gray-800 overflow-y-auto vendor-details-content">
              <div className="p-8 lg:pr-[28rem]">
                <div 
                  className="text-lg vendor-description prose prose-invert text-white"
                  dangerouslySetInnerHTML={{ __html: sanitizedInfo }}
                  role="region"
                  aria-label="Vendor Description"
                />
              </div>
            </div>
            <div className="lg:absolute lg:right-8 lg:top-[10%] w-full lg:w-80 mt-8 lg:mt-0 vendor-details-vendor-card">
              <div className="bg-[#2d3035] rounded-lg shadow-xl overflow-hidden hover:shadow-2xl transition-shadow duration-300" style={{ border: '1px solid #D1D5DB' }}>
                {vendor.logo_url && (
                  <div className="w-full h-full bg-white flex items-center justify-center" style={{ border: '1px solid #D1D5DB' }}>
                    <img 
                      src={vendor.logo_url} 
                      alt={`${vendor.name} logo`} 
                      className="max-w-full max-h-full object-contain"
                      loading="lazy"
                    />
                  </div>
                )}
                <div className="p-6 border border-gray-700 border-t-0 flex flex-col">
                  <h2 className="text-2xl font-semibold text-center mb-6">{vendor.name}</h2> 
                  <div className="mb-6 flex-grow">
                    <h3 className="text-lg font-semibold mb-3">Contact Information</h3> 
                    
                    {/* Website URL */}
                    {vendor.website_url ? (
                      <a 
                        href={vendor.website_url.startsWith('http') ? vendor.website_url : `https://${vendor.website_url}`}
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-blue-400 hover:underline block mb-3 text-base" 
                        aria-label={`Visit ${vendor.name}'s website`}
                      >
                        {vendor.website_url}
                      </a>
                    ) : (
                      <p className="text-gray-400 mb-3 text-base">No website available</p>
                    )}

                    {/* Phone Number */}
                    {vendor.phone && vendor.phone !== 'N/A' ? (
                      <div className="relative mb-3">
                        <div
                          onClick={() => handlePhoneClick(formatPhoneNumber(vendor.phone))}
                          className="text-communityBlue hover:underline inline-block text-base cursor-pointer"
                          aria-label={`Copy ${vendor.name}'s phone number`}
                          role="button"
                          tabIndex={0}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              handlePhoneClick(formatPhoneNumber(vendor.phone));
                            }
                          }}
                        >
                          {formatPhoneNumber(vendor.phone)}
                        </div>
                        {showCopied && (
                          <span className="ml-2 text-white text-sm animate-fade-in-out">
                            Copied!
                          </span>
                        )}
                      </div>
                    ) : (
                      <p className="text-gray-400 mb-3 text-base">No phone number available</p>
                    )}

                    {/* Email Button */}
                    <div className="flex justify-center mt-4">
                      <button 
                        onClick={() => handleEmailClick(vendor.email)}
                        className={`font-bold py-3 px-6 rounded text-base transition-colors duration-300 w-full ${
                          vendor.email && vendor.email !== 'N/A'
                            ? 'bg-white hover:bg-gray-100 text-black cursor-pointer'
                            : 'bg-gray-500 text-gray-300 cursor-not-allowed'
                        }`}
                        disabled={!vendor.email || vendor.email === 'N/A'}
                        aria-label={vendor.email && vendor.email !== 'N/A' ? `Email ${vendor.name}` : 'Email not available'}
                      >
                        {vendor.email && vendor.email !== 'N/A' ? 'Email Vendor' : 'Email Not Available'}
                      </button>
                    </div>
                  </div>
                  <div className="mt-auto mb-4 text-center">
                    <div className="flex items-center">
                      <div 
                        className={userRating === null ? "cursor-pointer" : "cursor-default"}
                        aria-label={userRating === null ? "Rate this vendor" : "Your rating"}
                      >
                        <StarRating 
                          rating={userRating || averageRating} 
                          readonly={userRating !== null}
                          onRatingChange={handleRatingClick}
                        />
                      </div>
                      <span 
                        className="ml-2 cursor-pointer hover:underline text-sm"
                        onClick={() => setShowRatingsOverview(true)}
                      >
                        ({ratingCount} ratings)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RatingModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedRating(null);
          }}
          onSubmit={handleRatingSubmit}
          initialRating={selectedRating || userRating}
        />
        {showRatingsOverview && (
          <RatingsOverview
            vendorId={id!}
            onClose={() => setShowRatingsOverview(false)}
          />
        )}
      </MainLayoutWrapper>
    </div>
  );
};

export default VendorDetails;