import React, { useState } from "react";

// Define TypeScript interface for the props of AnnouncementCard
interface AnnouncementCardProps {
  title: string;
  info: string;
  date: string;
  onButtonClick: () => void;
}

const AnnouncementCard: React.FC<AnnouncementCardProps> = ({
  title,
  info,
  date,
  onButtonClick,
}) => {
  const div = document.createElement("div");
  div.innerHTML = info;
  const text = div.textContent || div.innerText || "";

  // convert the timestamp to a date string in the format of "DD/MM/YYYY"
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  const dateString = `${month}/${day}/${year}`;

  const truncatedContent =
    text?.length > 150 ? `${text.substring(0, 150)}...` : text;
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => setIsPressed(true);
  const handleMouseUp = () => setIsPressed(false);

  const animationClass = isPressed ? "scale-95" : "scale-100";

  return (
    <div className="px-6 py-4 w-full">
      <div>
        <h2 className="font-semibold mb-2 max-sm:text-center">{title}</h2>
      </div>
      <div className="flex sm:flex-row flex-col items-center justify-between mt-4">
        <span className="text-sm text-gray max-sm:mb-2">{dateString}</span>
        <button
          type="button"
          onClick={onButtonClick}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp} // To reset state if cursor leaves the button
          onTouchStart={handleMouseDown} // For touch devices
          onTouchEnd={handleMouseUp} // For touch devices
          className={`bg-white text-black font-bold py-2 px-4 mr-[2rem] rounded focus:outline-none focus:shadow-outline transform transition duration-150 ease-in-out ${animationClass} cursor-pointer border-solid max-sm:w-full`}
        >
          Read Full Update
        </button>
      </div>
    </div>
  );
};

// Define TypeScript interface for an announcement object
interface AnnouncementCardsProps {
  announcements: {
    id: string;
    title: string;
    description: string;
    created_at: string;
    info: string;
    expired: boolean;
  }[];
  activeAnnouncement: {
    id: string;
    title: string;
    description: string;
    created_at: string;
    info: string;
    cover_image: string;
    expired: boolean;
  }[];
  setActiveAnnouncement: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        title: string;
        description: string;
        created_at: string;
        info: string;
        expired: boolean;
      }[]
    >
  >;
  setAnnouncements: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        title: string;
        description: string;
        created_at: string;
        info: string;
        cover_image: string;
        expired: boolean;
      }[]
    >

  >;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}


const AnnouncementCards: React.FC<AnnouncementCardsProps> = ({
  announcements,
  setAnnouncements,
  activeAnnouncement,
  setActiveAnnouncement,
  setIsModalOpen,
}) => {

  interface Announcement {
    id: string;
    title: string;
    description: string;
    created_at: string;
    info: string;
    expired: boolean;
  }


  const handleButtonClick = (announcement: Announcement) => {
    setActiveAnnouncement([announcement]);
    setIsModalOpen(true);
  };

  // todo: limit the number of announcements to 3

  console.log(announcements)

  return (
    <div className="flex flex-col w-full text-white">
      <div className="w-full text-white">
        {announcements.length === 0 ? (
          <div className="flex items-center justify-center h-64">
            <p className="text-2xl text-white">
              There are no future announcements
            </p>
          </div>
        ) : (
          announcements.map((announcement, index) => (
            <React.Fragment key={index}>
              <AnnouncementCard
                title={announcement.title}
                info={announcement.description}
                date={announcement.created_at}
                onButtonClick={() => handleButtonClick(announcement)}
              />
              {index < announcements.length - 1 && (
                <hr className="my-4 ml-[1rem] border-t border-gray border-turquoise" />
              )}
            </React.Fragment>
          ))
        )}

      </div>
    </div>
  );
};

export default AnnouncementCards;
