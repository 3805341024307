import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import StaffAndAdminSidebar from './components/Sidebar/StaffAndAdminSidebar';
import Dashboard from './components/Dashboard/Dashboard';
import Analytics from './components/Analytics/Analytics';
import Settings from './components/Settings/Settings';
import MainLayoutWrapper from '../../components/Layout/MainLayoutWrapper';
import Users from '../admin/users';
import EditUsers from '../admin/users/editUsers';
import Tools from './components/Tools/Tools';
import DynamicForm from './components/DailyEmailForm';
import AdminPanel from './components/AdminPanel/AdminPanel';
import DailyUpdatesApproval from './components/DailyUpdatesApproval/DailyUpdatesApproval';
import ProtectedRoute from './components/ProtectedRoute';

// Import the admin components
import AddAnnouncement from '../admin/announcements';
import CreatePartner from '../admin/partners/Create_Partner';
import EditPartner from '../admin/partners/EditPartner';
import CreateVendor from '../admin/vendors/Create_Vendor';
import EditVendor from '../admin/vendors/EditVendor';
import Vendors from '../Recommended_Vendors/Vendors'
import Partners from '../partners/Partners'
import NewEventModal from '../../components/Events/NewEventModal'; // Add this import
import SurveyManager from './components/SurveyManager/SurveyManager';
import EmailTemplateBuilder from './components/EmailTemplateBuilder/EmailTemplateBuilder';
import NotificationsManager from './components/Notifications/Notifications';

const StaffAndAdminPage: React.FC = () => {
  const [showEventModal, setShowEventModal] = useState(false);

  return (
    <div className="flex h-screen">
      <StaffAndAdminSidebar />
      <MainLayoutWrapper>
        <div className="container mx-auto px-6 py-8">
          <Routes>
            {/* Staff/Admin Routes */}
            <Route path="dashboard" element={
              <ProtectedRoute requireStaff>
                <Dashboard />
              </ProtectedRoute>
            } />
            <Route path="tools" element={
              <ProtectedRoute requireStaff>
                <Tools />
              </ProtectedRoute>
            } />
            <Route path="tools/daily-email-form" element={
              <ProtectedRoute requireStaff>
                <DynamicForm />
              </ProtectedRoute>
            } />
            <Route path="daily-updates-approval" element={
              <ProtectedRoute requireStaff>
                <DailyUpdatesApproval />
              </ProtectedRoute>
            } />

            {/* Admin-only Routes */}
            <Route path="analytics" element={
              <ProtectedRoute requireAdmin>
                <Analytics />
              </ProtectedRoute>
            } />
            <Route path="users" element={
              <ProtectedRoute requireAdmin>
                <Users />
              </ProtectedRoute>
            } />
            <Route path="users/edit/:id" element={
              <ProtectedRoute requireAdmin>
                <EditUsers />
              </ProtectedRoute>
            } />
            <Route path="settings" element={
              <ProtectedRoute requireAdmin>
                <Settings />
              </ProtectedRoute>
            } />
            <Route path="admin-panel" element={
              <ProtectedRoute requireAdmin>
                <AdminPanel setShowEventModal={setShowEventModal} />
              </ProtectedRoute>
            } />
            <Route path="announcements/create" element={
              <ProtectedRoute requireAdmin>
                <AddAnnouncement />
              </ProtectedRoute>
            } />
            <Route path="partners/create" element={
              <ProtectedRoute requireAdmin>
                <CreatePartner />
              </ProtectedRoute>
            } />
            <Route path="partners/edit" element={
              <ProtectedRoute requireAdmin>
                <Partners editMode={true} />
              </ProtectedRoute>
            } />
            <Route path="vendors/create" element={
              <ProtectedRoute requireAdmin>
                <CreateVendor isStaffAdmin={true} />
              </ProtectedRoute>
            } />
            <Route path="vendors/edit" element={
              <ProtectedRoute requireAdmin>
                <Vendors editMode={true} />
              </ProtectedRoute>
            } />
            <Route path="partners/edit/:id" element={
              <ProtectedRoute requireAdmin>
                <EditPartner />
              </ProtectedRoute>
            } />
            <Route path="vendors/edit/:id" element={
              <ProtectedRoute requireAdmin>
                <EditVendor isAdminRoute={true} />
              </ProtectedRoute>
            } />
            <Route path="survey-manager" element={
              <ProtectedRoute requireAdmin>
                <SurveyManager />
              </ProtectedRoute>
            } />
            <Route path="notifications" element={
              <ProtectedRoute requireAdmin>
                <NotificationsManager />
              </ProtectedRoute>
            } />
            <Route path="email-templates" element={
              <ProtectedRoute requireAdmin>
                <EmailTemplateBuilder />
              </ProtectedRoute>
            } />

            {/* Catch-all redirect */}
            <Route path="*" element={<Navigate to="/staff-admin/dashboard" replace />} />
          </Routes>
        </div>
      </MainLayoutWrapper>
      {showEventModal && (
        <NewEventModal
          showModal={showEventModal}
          setShowModal={setShowEventModal}
          setList={() => {}}
          setEventData={() => {}}
          activeEvent={[]}
        />
      )}
    </div>
  );
};

export default StaffAndAdminPage;
