import React, { useState, useEffect } from 'react';
import supabaseConnect from '../../../../utils/supabaseConnect';
import { FaSpinner, FaSearch } from 'react-icons/fa';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

interface Profile {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
}

interface NotificationForm {
  title: string;
  message: string;
  category: string;
  recipients: 'all' | 'specific';
  selectedUsers: string[];
}

const NotificationsManager: React.FC = () => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<NotificationForm>({
    title: '',
    message: '',
    category: 'System',
    recipients: 'all',
    selectedUsers: []
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  const categories = ['System', 'Updates', 'Marketing', 'Events', 'Important'];

  useEffect(() => {
    loadProfiles();
  }, []);

  const loadProfiles = async () => {
    try {
      const supabase = supabaseConnect();
      const { data, error } = await supabase
        .from('profiles')
        .select('id, first_name, last_name, email')
        .eq('active', true);

      if (error) throw error;
      setProfiles(data || []);
    } catch (error) {
      console.error('Error loading profiles:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const supabase = supabaseConnect();
      
      // Validate form data
      if (form.recipients === 'specific' && form.selectedUsers.length === 0) {
        throw new Error('Please select at least one recipient');
      }

      // Prepare notifications array
      const notifications = form.recipients === 'all'
        ? profiles.map(profile => ({
            profile_id: profile.id,
            title: form.title,
            message: form.message,
            category: form.category,
            read: false,
            created_at: new Date().toISOString()
          }))
        : form.selectedUsers.map(userId => ({
            profile_id: userId,
            title: form.title,
            message: form.message,
            category: form.category,
            read: false,
            created_at: new Date().toISOString()
          }));

      // Log for debugging
      console.log('Sending notifications:', notifications);

      const { data, error } = await supabase
        .from('notifications')
        .insert(notifications)
        .select();

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      console.log('Notifications sent successfully:', data);

      // Reset form
      setForm({
        title: '',
        message: '',
        category: 'System',
        recipients: 'all',
        selectedUsers: []
      });

      // Show success message
      setSnackbar({
        open: true,
        message: `Successfully sent ${notifications.length} notification${notifications.length !== 1 ? 's' : ''}!`,
        severity: 'success'
      });
    } catch (error) {
      console.error('Error sending notifications:', error);
      setSnackbar({
        open: true,
        message: error instanceof Error ? error.message : 'Failed to send notifications. Please try again.',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const filteredProfiles = searchQuery.trim() === '' 
    ? profiles 
    : profiles.filter(profile => {
        const searchTerm = searchQuery.toLowerCase();
        const fullName = `${profile.first_name} ${profile.last_name}`.toLowerCase();
        const email = profile.email.toLowerCase();
        
        return fullName.includes(searchTerm) || email.includes(searchTerm);
      });

  return (
    <div className="bg-[#100f0f] text-[#f1f2f6] p-6 rounded-lg -ml-5 -mt-5">
      <h2 className="text-2xl font-bold mb-6">Create Notification</h2>
      
      <form onSubmit={handleSubmit} className="space-y-6 w-full max-w-3xl">
        <div className="w-full">
          <label className="block mb-2">Title</label>
          <input
            type="text"
            value={form.title}
            onChange={e => setForm({ ...form, title: e.target.value })}
            className="w-full bg-[#1f1f23] border border-[#2d2d2d] rounded p-2 text-white"
            required
          />
        </div>

        <div className="w-full">
          <label className="block mb-2">Message (Optional)</label>
          <textarea
            value={form.message}
            onChange={e => setForm({ ...form, message: e.target.value })}
            className="w-full bg-[#1f1f23] border border-[#2d2d2d] rounded p-2 text-white h-24"
          />
        </div>

        <div className="w-[15rem]">
          <label className="block mb-2">Category</label>
          <select
            value={form.category}
            onChange={e => setForm({ ...form, category: e.target.value })}
            className="w-full bg-[#1f1f23] border border-[#2d2d2d] rounded p-2 text-white"
          >
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <label className="block mb-2">Recipients</label>
          <div className="space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                checked={form.recipients === 'all'}
                onChange={() => setForm({ ...form, recipients: 'all', selectedUsers: [] })}
                className="text-communityBlue"
              />
              <span>All Users</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                checked={form.recipients === 'specific'}
                onChange={() => setForm({ ...form, recipients: 'specific' })}
                className="text-communityBlue"
              />
              <span>Specific Users</span>
            </label>
          </div>
        </div>

        {form.recipients === 'specific' && (
          <div className="w-full">
            <label className="block mb-2">Select Users</label>
            
            <div className="w-full relative mb-2">
              <input
                type="text"
                placeholder="Search users..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-[15rem] bg-[#1f1f23] border border-[#2d2d2d] rounded p-2 pl-8 text-white"
              />
              <FaSearch className="absolute left-2.5 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>

            <div className="w-full max-h-64 overflow-y-auto bg-[#1f1f23] border border-[#2d2d2d] rounded p-2">
              <div className="space-y-2">
                <label className="flex items-center p-2 hover:bg-[#2d2d2d] rounded cursor-pointer group">
                  <input
                    type="checkbox"
                    checked={form.selectedUsers.length === profiles.length}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        selectedUsers: e.target.checked ? profiles.map(p => p.id) : []
                      });
                    }}
                    className="mr-3 text-communityBlue rounded border-gray-400 focus:ring-communityBlue"
                  />
                  <span className="text-white group-hover:text-communityBlue">Select All Users</span>
                </label>
                
                <div className="border-t border-[#2d2d2d] my-2"></div>
                
                {filteredProfiles.length === 0 ? (
                  <div className="text-center py-4 text-gray-400">
                    No users found matching "{searchQuery}"
                  </div>
                ) : (
                  filteredProfiles.map(profile => (
                    <label 
                      key={profile.id} 
                      className="flex items-center p-2 hover:bg-[#2d2d2d] rounded cursor-pointer group"
                    >
                      <input
                        type="checkbox"
                        checked={form.selectedUsers.includes(profile.id)}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            selectedUsers: e.target.checked
                              ? [...form.selectedUsers, profile.id]
                              : form.selectedUsers.filter(id => id !== profile.id)
                          });
                        }}
                        className="mr-3 text-communityBlue rounded border-gray-400 focus:ring-communityBlue"
                      />
                      <div className="flex flex-col">
                        <span className="text-white group-hover:text-communityBlue">
                          {profile.first_name} {profile.last_name}
                        </span>
                        <span className="text-sm text-gray-400">
                          {profile.email}
                        </span>
                      </div>
                    </label>
                  ))
                )}
              </div>
            </div>
            <div className="mt-2 text-sm text-gray-400">
              {form.selectedUsers.length} user{form.selectedUsers.length !== 1 ? 's' : ''} selected
            </div>
          </div>
        )}

        <div className="w-full">
          <button
            type="submit"
            disabled={isLoading}
            className="w-fit bg-bakcgroundColor text-white py-2 px-8 rounded hover:bg-communityBlue transition-colors duration-200 disabled:opacity-50 flex items-center cursor-pointer"
          >
            {isLoading ? (
              <FaSpinner className="animate-spin" />
            ) : (
              'Send Notification'
            )}
          </button>
        </div>
      </form>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ 
            width: '100%',
            backgroundColor: '#1d2025',
            '& .MuiAlert-icon': {
              color: snackbar.severity === 'success' ? '#4CAF50' : '#f44336'
            }
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NotificationsManager;
