import supabaseConnect from '../../utils/supabaseConnect';
import { SessionData, PageViewData, LinkClickData } from '../types/analytics.types';

const supabase = supabaseConnect();

/**
 * Service for handling analytics data storage and retrieval
 * @class AnalyticsService
 */
export class AnalyticsService {
  /**
   * Creates a new session in the database
   * @param sessionData - The session data to store
   */
  async createSession(sessionData: SessionData) {
    const { data, error } = await supabase
      .from('user_sessions')
      .insert([{
        session_id: sessionData.session_id,
        user_id: sessionData.user_id,
        user_agent: sessionData.user_agent,
        device_type: sessionData.device_type,
        browser: sessionData.browser,
        start_time: sessionData.start_time
      }]);
    
    if (error) throw error;
    return data;
  }

  /**
   * Records a page view
   * @param pageViewData - The page view data to store
   */
  async recordPageView(pageViewData: PageViewData) {
    const { data, error } = await supabase
      .from('page_views')
      .insert([pageViewData]);
    
    if (error) throw error;
    return data;
  }

   /**
   * Updates the session end time
   * @param sessionId - The session to update
   * @param endTime - The session end timestamp
   */
   async updateSessionEndTime(sessionId: string, endTime: Date) {
    const { error } = await supabase
      .from('user_sessions')
      .update({ end_time: endTime })
      .eq('session_id', sessionId);
    
    if (error) throw error;
  }

  /**
   * Records a link click
   * @param linkClickData - The link click data to store
   */
  async recordLinkClick(linkClickData: LinkClickData) {
    const { data, error } = await supabase
      .from('link_clicks')
      .insert([linkClickData]);
    
    if (error) throw error;
    return data;
  }
}

export const analyticsService = new AnalyticsService(); 