import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useNavigate } from 'react-router-dom';
import AnnouncementModal from "./AnnouncementModal";
import Sidebar from "../../components/Sidebar/Sidebar";
import { rectangle232xredOranage } from "../../assets";
import AnnouncementCards from "./AnnouncementCard";
import supabaseConnect from "../../utils/supabaseConnect";

interface AnnouncementData {
  id: string;
  title: string;
  description: string;
  created_at: string;
  info: string;
  cover_image: string;
  expired: boolean;
}

const ArchivedAnnouncements: React.FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayCount, setDisplayCount] = useState<number>(3);
  const [announcements, setAnnouncements] = useState<AnnouncementData[]>([]);
  const [activeAnnouncement, setActiveAnnouncement] = useState<AnnouncementData[]>([]);
  const [latestDate, setLatestDate] = useState<string>("");
  const supabase = supabaseConnect();

  useEffect(() => {
    getArchivedAnnouncements();
  }, []);

  const getArchivedAnnouncements = async () => {
    const { data, error } = await supabase
      .from("announcement")
      .select("*")
      .eq('expired', true)
      .order("created_at", { ascending: false });

    if (error) {
      console.log(error);
      return;
    }
    
    if (data) {
      const latestDate = data?.[0]?.created_at;
      const date = new Date(latestDate);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate = date.toLocaleDateString("en-US", options);

      setLatestDate(formattedDate);
      setAnnouncements(data);
    }
  };

  const loadMore = () => {
    setDisplayCount(prevCount => prevCount + 3);
  };

  const handleBackToActive = () => {
    navigate('/announcements');
  };

  return (
    <>
      <AnnouncementModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        activeAnnouncment={activeAnnouncement}
      />
      <div id="modal-root"></div>
      <div className="flex w-screen h-screen overflow-hidden text-left text-white font-inter">
        <Sidebar />
        <div className="w-full h-screen overflow-y-auto text-xs text-gray p-[1rem] lg:mt-0 mt-10">
          <div className="relative mt-10">
            {/* Back to Active button positioned over banner */}
            <div className="absolute top-4 right-4 z-10">
              <button
                onClick={handleBackToActive}
                className="bg-gray-800/80 hover:bg-gray-700 text-white px-4 py-2 rounded-md text-sm flex items-center gap-2 backdrop-blur-sm cursor-pointer"
                aria-label="View active announcements"
              >
                <span>View Active Announcements</span>
              </button>
            </div>

            <img
              className="relative top-0 rounded-t-md rounded-b-none h-[15rem] w-full object-cover"
              alt=""
              src={rectangle232xredOranage}
            />
            <div className="absolute w-full top-10 left-1/2 transform -translate-x-1/2 flex items-center justify-center flex-col">
              <div className="text-sm font-bold bg-white tracking-[0.06em] leading-[150%] text-center w-[7rem] sm:mb-4 mb-2 sm:-mt-4 -mt-6 text-gray-700">
                ARCHIVES
              </div>
              <div className="text-white text-2xl sm:text-3xl md:text-4xl sm:tracking-[0.06em] leading-[150%] sm:mb-[.8rem] text-center w-full">
                Archived Announcements
              </div>
              <div className="text-white text-sm tracking-[0.06em] leading-[150%] text-center w-full mb-[.5rem]">
                View past announcements from Community.
              </div>
              <div className="text-white text-sm tracking-[0.06em] leading-[150%] text-center w-full">
                <span className="text-xs opacity-[0.5]">{latestDate}</span>
              </div>
            </div>
          </div>

          <div className="flex relative w-full bg-black -mt-8 h-auto justify-between pb-[5rem]">
            <div className="flex flex-col p-2 sm:p-6 w-full text-xs">
              <div className="flex flex-col pr-8 border-solid border-[.1px] border-gray-500">
                <AnnouncementCards
                  setAnnouncements={setAnnouncements}
                  announcements={announcements.slice(0, displayCount)}
                  activeAnnouncement={activeAnnouncement}
                  setActiveAnnouncement={
                    setActiveAnnouncement as Dispatch<
                      SetStateAction<
                        {
                          id: string;
                          title: string;
                          description: string;
                          created_at: string;
                          info: string;
                          expired: boolean;
                        }[]
                      >
                    >
                  }
                  setIsModalOpen={setIsModalOpen}
                />
              </div>
              {displayCount < announcements.length && (
                <div className="flex justify-center mt-4">
                  <div
                    onClick={loadMore}
                    className="text-white pl-[1.2rem] text-[.8rem] cursor-pointer pt-2"
                  >
                    Load More
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArchivedAnnouncements; 