import React from 'react';
import { ActiveUsersAnalytics } from './components/ActiveUsersAnalytics';
import { UserActivityChart } from './components/UserActivityChart';
import { MostActiveUsers } from './components/MostActiveUsers';
import { ActivityTimelineChart } from './components/ActivityTimelineChart';

const Analytics: React.FC = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold text-white mb-6 -mt-8">Analytics Dashboard</h1>
      <div className="space-y-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <ActiveUsersAnalytics />
          <MostActiveUsers />
        </div>
        <ActivityTimelineChart />
        <UserActivityChart />
      </div>
    </div>
  );
};

export default Analytics;