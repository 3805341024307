import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import CardGrid, { CardProps } from "./ResourceCards";
import supabaseConnect from "../../utils/supabaseConnect";
import Search from "../dashboard/Search";
import MainLayoutWrapper from "../../components/Layout/MainLayoutWrapper";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { magnifyingglass } from "../../assets";
import Button from "../../components/Button/Button";
import { orderByRelevance } from "../../utils/searchUtils";
import { useNavigate } from 'react-router-dom';

interface Resource extends CardProps {
  body: string;
}

const ResourcesPageHome = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const divRef = useRef<HTMLDivElement>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<Resource[]>([]);
  const [activePageData, setActivePageData] = useState<Resource[]>([]);
  const supabase = supabaseConnect();
  const [displayedItems, setDisplayedItems] = useState<Resource[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true when fetching initial data
      const { data: { user } } = await supabase.auth.getUser();
      const userRole = await getUserPerms(user?.id);

      // Default resources
      const defaultResources: Resource[] = [
        {
          id: 1,
          title: "Resources for Everyone",
          description: "Community Resources for Everyone",
          body: "This is the body of the resource",
          tag: "Resource",
          cover_image: "https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/communityLogo.jpg",
          tag_id: 1,
          parent_directory: "Resource_Home_Page",
          link: "https://drive.google.com/drive/folders/1i5l_2L2T5pm5rIyZpljc4puS4usI6gDB?dmr=1&ec=wgc-drive-globalnav-goto"
        },
        {
          id: 2,
          title: "Resources for Agents",
          description: "Community Resources for Agents",
          body: "This is the body of the resource",
          tag: "Resource",
          cover_image: "https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/communityLogo.jpg",
          tag_id: 1,
          parent_directory: "Resource_Home_Page",
          link: "https://drive.google.com/drive/folders/1gx9RGi9ZP0el7QBl4wF9uwC2nbegn5qg?dmr=1&ec=wgc-drive-globalnav-goto"
        },
      ];

      if (userRole === "Staff" || userRole === "Admin") {
        defaultResources.push({
          id: 3,
          title: "Resources for Staff",
          description: "Community Resources for Staff",
          body: "This is the body of the resource",
          tag: "Resource",
          cover_image: "https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/communityLogo.jpg",
          tag_id: 1,
          parent_directory: "Resource_Home_Page",
          link: "https://drive.google.com/drive/folders/1ktiatuVjk4KnNZJEqWCSB-vmNULGnKL8?dmr=1&ec=wgc-drive-globalnav-goto"
        });
      }

      // Fetch all resources
      const { data: linkData, error: linkDataError } = await supabase
        .from('link')
        .select('*')
        .eq('tag', 'Resource');

      if (linkDataError) console.log("error", linkDataError);

      let resources: Resource[] = linkData?.map((item: any) => ({
        id: item.id,
        title: item.name,
        description: item.description,
        body: item.description,
        tag: item.tag,
        cover_image: item.cover_image || "https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/communityLogo.jpg",
        tag_id: item.tag_id,
        link: item.link,
        parent_directory: item.parent_directory,
      })) ?? [];

      // Combine default resources with fetched resources
      resources = [...defaultResources, ...resources];

      // Filter resources based on user role
      if (userRole === "Agent") {
        resources = resources.filter((item: Resource) => 
          item.parent_directory === "Agent Resources" || 
          item.parent_directory === "_Everyone" ||
          item.parent_directory === "Resource_Home_Page"
        );
      }

      // Organize resources into a hierarchical structure
      const organizedResources = resources.reduce((acc: Resource[], item: Resource) => {
        if (!item.parent_directory || item.parent_directory === "Resource_Home_Page") {
          acc.push(item);
        } else {
          const parentIndex = acc.findIndex((resource: Resource) => resource.title === item.parent_directory);
          if (parentIndex !== -1) {
            if (!acc[parentIndex].children) {
              acc[parentIndex].children = [];
            }
            acc[parentIndex].children!.push(item);
          } else {
            acc.push({
              id: Number(`parent_${item.parent_directory}`),
              title: item.parent_directory,
              description: `Resources for ${item.parent_directory}`,
              body: "",
              tag: "Resource",
              cover_image: "https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/communityLogo.jpg",
              tag_id: 0,
              parent_directory: "",
              children: [item]
            });
          }
        }
        return acc;
      }, []);

      setPageData(organizedResources);
      setUserRole(userRole);
      setLoading(false); // Set loading to false when done fetching

      // Add this at the end of the useEffect
      setDisplayedItems(organizedResources.slice(0, itemsPerPage));
    };

    fetchData();
  }, []);

  const getUserPerms = async (userId: string | undefined) => {
    if (!userId) return null;

    const { data: profileRoleData, error: profileRoleDataError } = await supabase
      .from('profile_role')
      .select('*')
      .eq('user_id', userId)
      .single();

    if (profileRoleDataError) {
      console.log("error", profileRoleDataError);
      return null;
    }

    const { data: roledata, error: roledataError } = await supabase
      .from('role')
      .select('*')
      .eq('id', profileRoleData?.role_id)
      .single();

    if (roledataError) {
      console.log("error", roledataError);
      return null;
    }

    return roledata?.role_name;
  };

  const fetchResults = async (table: string, column: string, searchTerm: string) => {
    return await supabase
      .from(table)
      .select("*")
      .textSearch(column, searchTerm || "", { type: "websearch" });
  };
  
  const processResults = (results: any, key: string, maxLength: number) => {
    if (results?.data) {
      results.data.forEach((item: any) => {
        item[key] = item[key].substring(0, maxLength);
      });
    }
  };

  const addTableName = (results: any, tableName: string) => {
    if (results?.data) {
      results.data.forEach((item: any) => (item.table_name = tableName));
    }
  };
  
  const search = async () => {
    console.log("Search term: ", searchTerm);
  
    // Instead of performing the search here, we'll navigate to the SearchResults page
    const searchParams = new URLSearchParams();
    searchParams.set('q', searchTerm.toLowerCase());
    navigate(`/search?${searchParams.toString()}`);
  };

  const handleClick = () => {
    window.location.href = "/page";
  };

  const handleCardClick = (item: Resource) => {
    if (item.children) {
      setActivePageData(item.children.map(child => ({
        ...child,
        body: child.body || child.description || ''
      })) as Resource[]);
      setCurrentPage(1);
      setDisplayedItems(item.children.map(child => ({
        ...child,
        body: child.body || child.description || ''
      })) as Resource[]);
    } else if (item.link) {
      window.open(item.link, "_blank");
    }
  };

  const loadMore = () => {
    const nextPage = currentPage + 1;
    const startIndex = (nextPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newItems = (activePageData.length > 0 ? activePageData : pageData).slice(startIndex, endIndex);
    setDisplayedItems([...displayedItems, ...newItems]);
    setCurrentPage(nextPage);
  };

  return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5">
          <div className="bg-black xl:p-12 lg:p-8 md:p-7 sm:p-6 p-3 flex sm:flex-row flex-col leading-[150%] justify-between items-center">
            <p className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-3xl sm:m-0 m-4 text-white cursor-pointer" onClick={handleClick}>
              Resource Pages
            </p>
            <div className="relative md:w-2/3 sm:w-7/12 w-full bg-white flex rounded-xl">
              <input
                className="flex-1 leading-[150%] p-4 rounded-lg w-fit bg-white focus:outline-none text-black border-none text-base"
                placeholder="Search Resources"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <div
                onClick={search}
                className="absolute top-0 -right-1 shadow max-xs:hidden"
              >
                <Search />
              </div>
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-[calc(100vh-200px)]">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <CardGrid
                data={displayedItems}
                onCardClick={handleCardClick as (item: CardProps) => void}
              />
              {displayedItems.length < (activePageData.length > 0 ? activePageData.length : pageData.length) && (
                <div className="flex justify-center mt-6">
                  <Button onClick={loadMore}>Load More</Button>
                </div>
              )}
              {pageData.length === 0 && searchTerm && (
                <div className="mt-10 ml-4 text-center">
                  No resource results found for "{searchTerm}"
                </div>
              )}
            </>
          )}
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default ResourcesPageHome;
