import React, { useEffect, useRef } from 'react';
import { marketingBanner } from '../../assets';
import Sidebar from '../../components/Sidebar/Sidebar';
import DownloadButton from '../../components/Buttons/DownloadButton';

/**
 * Download types for marketing assets
 * @typedef {'community-logo-black' | 'community-logo-white' | 'it-logo-black' | 'it-logo-white' | 
 * 'community-real-black' | 'community-real-white' | 'we-not-me' | 'make-it-happen' | 
 * 'dream-do-it' | 'be-a-boss' | 'embracethehow'} DownloadType
 */

/**
 * Handles the download of marketing assets by opening a Google Drive download link
 * @description This function manages the download of various marketing assets including logos,
 * brand materials, and core value graphics. It uses direct Google Drive download links to
 * serve the files to users.
 * 
 * @param {DownloadType} downloadType - The type of asset to download
 * @throws Will not throw but will silently fail if the download link is invalid
 * 
 * @example
 * handleDownload('community-logo-black')
 * // Opens download for black version of Community logo
 */
const handleDownload = (downloadType: string) => {
  console.log(downloadType);
  switch (downloadType) {
    case 'community-logo-black':
      window.open('https://drive.google.com/uc?export=download&id=1JtxhtkccH2IssY-b2i-rfuYUwx3ngaHa');
      break;
    case 'community-logo-white':
      window.open('https://drive.google.com/uc?export=download&id=1JW0moCTiLCNF90Ki2CAfQQ0q8Tnkou_A');
      break;
    case 'it-logo-black':
      window.open('https://drive.google.com/uc?export=download&id=1lcifHaT8Kz4L-rg-BULP70mpykN453cq');
      break;
    case 'it-logo-white':
      window.open('https://drive.google.com/uc?export=download&id=1qU4dp62AZTxSvTDqA39OxB2-_fFwdFb6');
      break;
    case 'community-real-dark':
      window.open('https://drive.google.com/uc?export=download&id=1_boFLQga7yrUNE8Ra2bduV_b1SZBM51d');
      break;
    case 'community-real-white':
      window.open('https://drive.google.com/uc?export=download&id=1e1cnnUnIQ9xJEYvJ6VsutctwonCaqtsD');
      break;
    case 'we-not-me':
      window.open('https://drive.google.com/uc?export=download&id=1frArho1LXDrwmyUsEQ5m5LwJ79VZsWYt');
      break;
    case 'make-it-happen':
      window.open('https://drive.google.com/uc?export=download&id=1tZqW8r2KbD_gf44x68bUQ206wFmELt7H');
      break;
    case 'dream-do-it':
      window.open('https://drive.google.com/uc?export=download&id=1Ok9Evk-GT9HK9dDrYpSPzIum7KHBbYZW');
      break;
    case 'be-a-boss':
      window.open('https://drive.google.com/uc?export=download&id=1HFmIQhfTf6BT2wAysILqY6zTjUg5uqHi');
      break;
    case 'embracethehow':
      window.open('https://drive.google.com/uc?export=download&id=1KslUoTRofce_DWIZ2D7finHIdOoZkGTm');
      break;
    case 'logo-animation':
      window.open('https://drive.google.com/uc?export=download&id=1KgkJ5YTWVUwmU_WJf2hj_VLcR8DtBxYW');
      break;
  }
};

/**
 * URLs for Community Real logo variations
 * @description Contains the URLs for both dark and white versions of the Community Real
 * combination logo stored in Supabase storage. These logos are used throughout the
 * marketing materials where the full brand combination needs to be displayed.
 * 
 * @constant
 * @type {{ readonly dark: string; readonly white: string }}
 * 
 * @example
 * <img src={realCommunityLogos.dark} alt="Dark Community Real Logo" />
 */
export const realCommunityLogos = {
  dark: 'https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/community-real-blackDark.png',
  white: 'https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/community-real-white.png'
} as const;

/**
 * VideoPlayer component that autoplays when in view
 * @param {Object} props - Component props
 * @param {string} props.src - Video source URL
 * @param {string} props.className - CSS classes for the video element
 */
const VideoPlayer = ({ src, className, style }: { src: string; className?: string; style?: React.CSSProperties }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    // Create intersection observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            video.play().catch((e) => console.log("Autoplay prevented:", e));
          } else {
            video.pause();
            video.currentTime = 0;
          }
        });
      },
      {
        threshold: 0.5, // 50% of the element must be visible
      }
    );

    // Start observing the video element
    observer.observe(video);

    // Cleanup
    return () => {
      observer.unobserve(video);
      observer.disconnect();
    };
  }, []);

  return (
    <video 
      ref={videoRef}
      className={className}
      playsInline
      muted
      loop
      style={style}
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

/**
 * MarketingCenterMain component
 * @description Main page for the marketing center that displays brand guidelines,
 * logos, and color schemes. This component serves as the central hub for all
 * marketing-related resources.
 */
const MarketingCenterMain = () => {
  return (
    <div className="flex w-full h-screen overflow-hidden text-black">
      <Sidebar />
      <div className="flex-1 overflow-auto">
        <div className="bg-gray">
          <header>
            <div className="w-full h-[200px] sm:h-full md:h-full lg:h-full relative -z-10">
              <img 
                src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/banner-marketing%20center-transparent.png" 
                alt="Marketing Center Banner" 
                className="w-full h-full object-contain"
              />
            </div>
          </header>

          <main className="mx-auto max-w-6xl px-4 py-2">
            <div className="rounded-lg bg-white p-4 shadow-lg -mt-4 sm:p-6 md:p-8">
              {/* Logo Section */}
              <section className="mb-12" style={{ borderBottom: '2px solid black', paddingBottom: '3rem' }}>
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 mb-8" 
                     style={{ borderBottom: '2px solid black', paddingBottom: '2rem' }}>
                  <h2 className="col-span-1 md:col-span-4 text-xl md:text-2xl font-semibold font-league-spartan">
                    The Community Logo
                  </h2>
                  <div className="col-span-1 md:col-span-8">
                    <p className="text-gray-700 mb-2">
                      The Community logo is designed to be a powerful and unmistakable symbol of our presence in Colorado, 
                      capturing the essence of strength and clarity. Rendered exclusively in black and white, the logo 
                      embodies the luxury, modernity, and clean aesthetic that defines our brand identity.
                    </p>
                    <p className="text-gray-700">
                      View our complete style guide {' '}
                      <a 
                        href="https://www.canva.com/design/DAGWR51WT24/ZZK_TmOPjAgjlmfvHW7jdQ/edit?utm_content=DAGWR51WT24&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                        className="text-communityBlue hover:text-blue-800 underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                  <div className="flex flex-col items-center">
                    <div className="mb-4 bg-white p-6">
                      <img 
                        src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/community-logo-black.png" 
                        alt="Community Logo Light"
                        className="h-auto w-full"
                      />
                    </div>
                    <DownloadButton onClick={() => handleDownload('community-logo-black')} />
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="mb-4 bg-black p-6">
                      <img 
                        src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/community-logo-white.png" 
                        alt="Community Logo Dark"
                        className="h-auto w-full"
                      />
                    </div>
                    <DownloadButton onClick={() => handleDownload('community-logo-white')} />
                  </div>
                </div>
              </section>

              {/* Misuse Section */}
              <section className="mb-12" style={{ borderBottom: '2px solid black', paddingBottom: '3rem' }}>
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 mb-8">
                  <h2 className="col-span-1 md:col-span-4 text-xl md:text-2xl font-semibold font-league-spartan">
                    Misuse of the Community Logo
                  </h2>
                  <ul className="col-span-1 md:col-span-8 list-disc pl-5 text-gray-700">
                    <li>Do not stretch, skew, or rotate the logo.</li>
                    <li>Avoid adding shadows, gradients, or extra effects.</li>
                    <li>Do not alter the colors outside of approved brand colors.</li>
                  </ul>
                </div>
                <div className="flex justify-center">
                  <div className="relative w-full max-w-6xl">
                    <img 
                      src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/sign/marketing-center/misues-examples.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJtYXJrZXRpbmctY2VudGVyL21pc3Vlcy1leGFtcGxlcy5wbmciLCJpYXQiOjE3MzM0ODUyNjYsImV4cCI6MjA0ODg0NTI2Nn0.6GePL6U_2NfB7DiqK4FvmGzBtkz_uLegJwZy82Z3N3g"
                      alt="Incorrect logo usage examples"
                      className="h-auto w-full"
                    />
                  </div>
                </div>
              </section>

              {/* IT Logo Mark Section */}
              <section className="mb-12" style={{ borderBottom: '2px solid black', paddingBottom: '3rem' }}>
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 mb-8">
                  <h2 className="col-span-1 md:col-span-4 text-xl md:text-2xl font-semibold font-league-spartan">
                    IT Logo Mark
                  </h2>
                  <p className="col-span-1 md:col-span-8 text-gray-700">
                    Use the icon alone when space is limited or in digital applications like social media. 
                    Use single-color variations for print or minimalist designs.
                    Do not stretch or alter the icon in any way.
                  </p>
                </div>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                  <div className="flex flex-col items-center">
                    <div className="mb-4 bg-white p-6 max-w-[200px]">
                      <img 
                        src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/it-black.png"
                        alt="IT Logo Black"
                        className="h-auto w-full"
                      />
                    </div>
                    <DownloadButton onClick={() => handleDownload('it-logo-black')} />
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="mb-4 bg-black p-6 max-w-[200px]">
                      <img 
                        src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/it-white.png"
                        alt="IT Logo White"
                        className="h-auto w-full"
                      />
                    </div>
                    <DownloadButton onClick={() => handleDownload('it-logo-white')} />
                  </div>
                </div>
              </section>

              {/* Real Logo Combination */}
              <section className="mb-12" style={{ borderBottom: '2px solid black', paddingBottom: '3rem' }}>
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 mb-8">
                  <h2 className="col-span-1 md:col-span-4 text-xl md:text-2xl font-semibold font-league-spartan">
                    Community Real Logo Combination
                  </h2>
                  <p className="col-span-1 md:col-span-8 text-gray-700">
                    The Community logo is designed to be a powerful and unmistakable symbol of our presence in Colorado, 
                    capturing the essence of strength and clarity. Rendered exclusively in black and white, the logo 
                    embodies the luxury, modernity, and clean aesthetic that defines our brand identity.
                  </p>
                </div>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                  {(Object.entries(realCommunityLogos) as [keyof typeof realCommunityLogos, string][]).map(([type, url]) => (
                    <div key={type} className="flex flex-col items-center">
                      <div className={`mb-4 p-6 ${type === 'white' ? 'bg-black' : 'bg-white'}`}>
                        <img 
                          src={url}
                          alt={`Community Real Logo ${type === 'dark' ? 'Black' : 'White'}`}
                          className="h-auto w-full"
                        />
                      </div>
                      <DownloadButton onClick={() => handleDownload(`community-real-${type}`)} />
                    </div>
                  ))}
                </div>
              </section>

              {/* Tri-Logos Section */}
              <section className="mb-12" style={{ borderBottom: '2px solid black', paddingBottom: '3rem' }}>
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 mb-8">
                  <h2 className="col-span-1 md:col-span-4 text-xl md:text-2xl font-semibold font-league-spartan">
                    Tri-Logos
                  </h2>
                  <div className="col-span-1 md:col-span-8">
                    <p className="mb-4 text-gray-700">
                      The Tri-Logo is designed specifically for agents approved to use their own personal branding 
                      while staying connected to the Community and REAL brands.
                    </p>
                  </div>
                </div>
                
                <div className="space-y-6">
                  <div className="flex items-center gap-4">
                    <h3 className="text-[25px] font-medium whitespace-nowrap mr-8">Examples of tri-logos</h3>
                    <div className="flex-1">
                      <div className="grid grid-cols-1 gap-6">
                        {/* Light Version */}
                        <div className="bg-white p-4 border border-gray-200 rounded-lg max-w-3xl">
                          <img 
                            src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/tri-logo-1.png"
                            alt="Tri-logo light example"
                            className="h-auto w-full max-w-2xl mx-auto"
                          />
                        </div>
                        
                        {/* Dark Version */}
                        <div className="bg-black p-4 rounded-lg max-w-3xl">
                          <img 
                            src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/tri-logo-2.png"
                            alt="Tri-logo dark example"
                            className="h-auto w-full max-w-2xl mx-auto"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* Color Hierarchy */}
              <section className="mb-12">
                <div className="relative">
                  <div className="relative h-[120px] sm:h-[150px] md:h-[200px] rounded-t-lg overflow-hidden">
                    {/* Background Banner */}
                    <img 
                      src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/color-banner.png"
                      alt="Color Hierarchy Banner"
                      className="w-full h-full object-cover object-left"
                    />
                  </div>
                  
                  <div className="p-4 sm:p-6 md:p-8">
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 mb-8">
                      <p className="col-span-1 md:col-span-8 text-gray-700">
                        Our accent colors should be used sparingly throughout illustration, photography, and marketing 
                        to maintain meaning and consistency.
                      </p>
                    </div>
                    
                    <div className="space-y-8">
                      {/* Primary Colors */}
                      <div>
                        <h3 className="mb-4 text-xl">Primary Colors</h3>
                        <div className="flex flex-col md:flex-row gap-4">
                          {/* Black */}
                          <div className="flex-1 bg-white rounded-lg shadow-lg overflow-hidden">
                            <div className="flex">
                              <div className="w-48 bg-black mr-2"></div>
                              <div className="p-1 flex-1">
                                <h4 className="font-medium text-[16px]">Black</h4>
                                <div className="text-[12px] text-gray-600">
                                  <p>HEX #000000</p>
                                  <p>RGB r: 0, g: 0, b: 0</p>
                                  <p>CMYK c: 0, m: 0, y: 0, k: 100</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          {/* White */}
                          <div className="flex-1 bg-white rounded-lg shadow-lg overflow-hidden">
                            <div className="flex">
                              <div className="w-48 bg-[#F1F1F1] border border-gray-400 mr-2"></div>
                              <div className="p-1 flex-1">
                                <h4 className="font-medium text-[16px]">White Smoke</h4>
                                <div className="text-[12px] text-gray-600">
                                  <p>HEX #F1F1F1</p>
                                  <p>RGB r: 241, g: 241, b: 241</p>
                                  <p>CMYK c: 0, m: 0, y: 0, k: 5</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Accent Colors */}
                      <div>
                        <h3 className="mb-4 text-xl">Accent Colors</h3>
                        <div className="flex flex-col md:flex-row gap-4">
                          {/* Sky Blue */}
                          <div className="flex-1 bg-white rounded-lg shadow-lg overflow-hidden">
                            <div className="flex">
                              <div className="w-32 bg-[#08C2F8] mr-2"></div>
                              <div className="p-1 flex-1">
                                <h4 className="font-medium text-[16px]">Sky Blue</h4>
                                <div className="text-[12px] text-gray-600 ">
                                  <p>HEX #08C2F8</p>
                                  <p>RGB r: 8, g: 194, b: 248</p>
                                  <p>CMYK c: 100, m: 0, y: 10, k: 0</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Watermelon */}
                          <div className="flex-1 bg-white rounded-lg shadow-lg overflow-hidden">
                            <div className="flex">
                              <div className="w-32 bg-[#FF557E] mr-2"></div>
                              <div className="p-1 flex-1">
                                <h4 className="font-medium text-[16px]">Watermelon</h4>
                                <div className="text-[12px] text-gray-600">
                                  <p>HEX #FF557E</p>
                                  <p>RGB r: 255, g: 85, b: 126</p>
                                  <p>CMYK c: 0, m: 67, y: 51, k: 0</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Gray */}
                          <div className="flex-1 bg-white rounded-lg shadow-lg overflow-hidden">
                            <div className="flex">
                              <div className="w-32 bg-[#808080] mr-2"></div>
                              <div className="p-1 flex-1">
                                <h4 className="font-medium text-[16px]">Gray</h4>
                                <div className="text-[12px] text-gray-600">
                                  <p>HEX #808080</p>
                                  <p>RGB r: 128, g: 128, b: 128</p>
                                  <p>CMYK c: 0, m: 0, y: 0, k: 50</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* Core Values Section */}
              <section className="mb-12" style={{ borderBottom: '2px solid black', paddingBottom: '3rem' }}>
                <div className="relative">
                  <div className="relative h-[120px] sm:h-[150px] md:h-[200px] rounded-t-lg overflow-hidden">
                    {/* Background Banner */}
                    <img 
                      src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/corevalues-banner.png"
                      alt="Core Values Banner"
                      className="w-full h-full object-cover object-left"
                    />
                  </div>
                  
                  <div className="p-4 sm:p-6 md:p-8">
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 mb-8">
                      <p className="col-span-1 md:col-span-8 text-gray-700">
                        Core value graphics are available for download, designed to visually communicate the fundamental 
                        principles that guide Community. Easy to incorporate, these graphics allow you to visually 
                        emphasize our core values consistently across your content.
                      </p>
                    </div>
                    
                    <div className="grid grid-cols-2 gap-8 md:grid-cols-5">
                      {/* Core Values Images */}
                      <div className="flex flex-col items-center">
                        <img 
                          src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/wenotme.1.png"
                          alt="We Not Me"
                          className="mb-4 h-auto w-full"
                        />
                        <DownloadButton onClick={() => handleDownload('we-not-me')} />
                      </div>
                      <div className="flex flex-col items-center">
                        <img 
                          src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/makeithappen.1.png"
                          alt="Make It Happen"
                          className="mb-4 h-auto w-full"
                        />
                        <DownloadButton onClick={() => handleDownload('make-it-happen')} />
                      </div>
                      <div className="flex flex-col items-center">
                        <img 
                          src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/dreamitdoit.1.png"
                          alt="Dream Do It"
                          className="mb-4 h-auto w-full"
                        />
                        <DownloadButton onClick={() => handleDownload('dream-do-it')} />
                      </div>
                      <div className="flex flex-col items-center">
                        <img 
                          src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/beaboss.1.png"
                          alt="Be A Boss"
                          className="mb-4 h-auto w-full"
                        />
                        <DownloadButton onClick={() => handleDownload('be-a-boss')} />
                      </div>
                      <div className="flex flex-col items-center">
                        <img 
                          src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/embracethehow.1.png"
                          alt="Embrace"
                          className="mb-4 h-auto w-full"
                        />
                        <DownloadButton onClick={() => handleDownload('embracethehow')} />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* Logo Animation Section */}
              <section className="mb-12">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 mb-8">
                  <h2 className="col-span-1 md:col-span-4 text-xl md:text-2xl font-semibold font-league-spartan">
                    Logo Animation
                  </h2>
                  <p className="col-span-1 md:col-span-8 text-gray-700">
                    The Community logo animation is available for download, perfectly suited for use in video 
                    intros and outros. This animation provides a dynamic and professional introduction to our 
                    brand, reinforcing our identity with elegance and precision. It's crafted to enhance brand 
                    recognition through a smooth, high-quality animation that aligns with our luxurious and 
                    modern aesthetic.
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-8">
                  <div className="w-full sm:w-2/3 md:w-1/2 lg:w-2/5">
                    <VideoPlayer 
                      src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/marketing-center/COM%20Logo%20Intro.mp4"
                      className="h-auto w-full rounded-lg"
                      style={{ border: '1px solid black' }}
                    />
                  </div>
                  <div className="flex-shrink-0 mt-4 sm:mt-0">
                    <DownloadButton onClick={() => handleDownload('logo-animation')} />
                  </div>
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default MarketingCenterMain;
