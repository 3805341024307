import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AnalyticsContext } from '../context/AnalyticsContext';
import { useSessionTracking } from '../hooks/useSessionTracking';
import { analyticsService } from '../services/analyticsService';
import { PageViewData, LinkClickData } from '../types/analytics.types';

/**
 * Provider component that wraps the app and provides analytics tracking functionality
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to be wrapped
 */
export const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { sessionId } = useSessionTracking();
  const [pageEnterTime, setPageEnterTime] = useState<Date>(new Date());

  /**
   * Records a page view with time spent calculation
   * @param url - The URL of the page being viewed
   * @param timeSpent - Optional time spent on page in seconds
   */
  const recordPageView = async (pageData: PageViewData) => {
    if (!sessionId) return;

    try {
      await analyticsService.recordPageView({
        ...pageData,
        session_id: sessionId,
      });
    } catch (error) {
      console.error('Failed to record page view:', error);
    }
  };

  /**
   * Records a link click event
   * @param linkData - Data about the clicked link
   */
  const recordLinkClick = async (linkData: LinkClickData) => {
    if (!sessionId) return;

    try {
      await analyticsService.recordLinkClick({
        ...linkData,
        sessionId,
      });
    } catch (error) {
      console.error('Failed to record link click:', error);
    }
  };

  // Track page views and time spent on page
  useEffect(() => {
    const handlePageView = async () => {
      const timeSpent = Math.floor(
        (new Date().getTime() - pageEnterTime.getTime()) / 1000
      );

      // Record the previous page view with time spent
      if (sessionId) {
        await recordPageView({
          session_id: sessionId,
          page_url: location.pathname,
          time_spent: timeSpent,
        });
      }

      // Reset the timer for the new page
      setPageEnterTime(new Date());
    };

    handlePageView();

    // Cleanup function to record final page view when component unmounts
    return () => {
      const finalTimeSpent = Math.floor(
        (new Date().getTime() - pageEnterTime.getTime()) / 1000
      );

      if (sessionId) {
        recordPageView({
          session_id: sessionId,
          page_url: location.pathname,
          time_spent: finalTimeSpent,
        });
      }
    };
  }, [location.pathname, sessionId]);

  const contextValue = {
    recordPageView,
    recordLinkClick,
    sessionId,
  };

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
}; 