import React, { useEffect, useState } from 'react';
import supabaseConnect from '../../../../../utils/supabaseConnect';
import { DonutChart } from './DonutChart';

interface ActiveUsersStats {
  totalUsers: number;
  activeUsers: number;
}

/**
 * Component for displaying active users analytics
 */
export const ActiveUsersAnalytics: React.FC = () => {
  const [stats, setStats] = useState<ActiveUsersStats>({
    totalUsers: 0,
    activeUsers: 0,
  });

  const supabase = supabaseConnect();

  useEffect(() => {
    const fetchActiveUsers = async () => {
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

      // Get total users
      const { count: totalUsers } = await supabase
        .from('profiles')
        .select('*', { count: 'exact', head: true });

      // Get active users in the last week
      const { data: activeUsersData, error } = await supabase
        .from('user_sessions')
        .select('user_id')
        .gte('created_at', oneWeekAgo.toISOString())
        .not('user_id', 'is', null);

      // Count unique user_ids
      const uniqueActiveUsers = new Set(activeUsersData?.map(session => session.user_id)).size;

      setStats({
        totalUsers: totalUsers || 0,
        activeUsers: uniqueActiveUsers || 0,
      });
    };

    fetchActiveUsers();
  }, []);

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-semibold mb-4">Active Users This Week</h2>
      <DonutChart
        total={stats.totalUsers}
        active={stats.activeUsers}
        label={stats.totalUsers.toString()}
        activeLabel={`${stats.activeUsers} Active Users`}
        inactiveLabel="No Recent Activity"
      />
      <p className="text-center mt-4 text-gray-600">
        {stats.activeUsers} out of {stats.totalUsers} users active this week
      </p>
    </div>
  );
};