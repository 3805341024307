// @ts-nocheck
import React, { useState, useEffect } from 'react'
import MainEditor from '../../../editor'
import Sidebar from '../../../components/Sidebar/Sidebar'
import supabaseConnect from '../../../utils/supabaseConnect'
import axios from 'axios';
import MainLayoutWrapper from '../../../components/Layout/MainLayoutWrapper'



const AddAnnouncement = () => {

  const [selectedOptions, setSelectedOptions] = useState([]);
  const supabase = supabaseConnect();
  const [options, setOptions] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [body, setBody] = useState("");
  const [coverImage, setCoverImage] = useState({});
  const [editor, setEditor] = useState(null);
  const clearContent = () => {
    setEditor("");
    setTitle("");
    setDescription("");
    setBody("");
  };
  let rolesId = [];
  const [expirationDate, setExpirationDate] = useState('');
  const [emailBlast, setEmailBlast] = useState(false);

  // todo: add auth logic that you have to be admin to view page

  const handleSubmit = async () => {
    // get text from inputs and editor and set to state
    // console.log('test')
    const title = document.getElementById("grid-title") as HTMLInputElement;
    const description = document.getElementById(
      "grid-description"
    ) as HTMLInputElement;
    const whoIsThisFor = options;

    const titleValue = title.value;
    const descriptionValue = description.value;
    const editorValue = editor;

    // submit resource to db
    await submitResource(titleValue, descriptionValue, editorValue);
  };

  const getAllImages = async () => {
    const { data, error } = await supabase.storage.from("media").list();

    if (error) alert(error?.message);

    console.log(data);

    return data;
  };

  const getRole = async (roles) => {
    for (let i = 0; i < roles.length; i++) {
      let roleName = roles[i];
      const { data, error } = await supabase
        .from("role")
        .select("*")
        .eq("role_name", roleName);

      rolesId.push(data[0]);

      if (error) {
        console.error("Error fetching options: ", error);
      } else {
        console.log(rolesId);
      }
    }
    return rolesId;
  };

  useEffect(() => {
    const fetchOptions = async () => {
      const { data, error } = await supabase.from("role").select();

      if (error) {
        console.error("Error fetching options: ", error);
      } else {
        // filter out the admin role
        const newData = data.filter((role) => role.role_name !== "Admin");
        setOptions(newData);
      }
    };

    fetchOptions();
  }, []);

  const sendEmail = async (user, titleValue, descriptionValue, editorValue, coverImage) => {
    const email = user.email;
    const emailData = {
      email,
      titleValue,
      descriptionValue,
      editorValue,
      coverImage,
      emailHTML: `
      <!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>New Announcement</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      color: #333333;
      margin: 0;
      padding: 0;
    }
    .banner {
      /* This is the new banner area */
      background-image: url('${coverImage || 'https://via.placeholder.com/150'}');
      background-size: cover;
      background-position: center;
      height: 7rem; /* Set a fixed height for the banner */
      width: 15rem
      display: flex;
      justify-content: center;
      align-items: center;
      color: white; /* Assuming white text for the banner */
      text-shadow: 1px 1px 0px rgba(0,0,0,0.5); /* Optional: Adding a shadow to the text for better readability */
    }
    h1 {
      color: #0077b6; /* You might want to change this if it becomes less legible on the banner */
    }
    .content {
      padding: 10px;
    }
    @media (max-width: 480px) {
      .banner {
        height: 150px;
      }
      h1 {
        font-size: 1.5rem; /* Adjusting size for visibility on smaller screens */
      }
      .content {
        font-size: 0.9rem;
      }
    }
  </style>
</head>
<body>
  <div class="banner">
  <img src="${coverImage || "https://via.placeholder.com/150"}" alt="cover image" />
  </div>
  <h1>${titleValue}</h1>
  <div class="content">
    <p>${descriptionValue}</p>
    <div>${editorValue}</div>
    <p>
      This announcement was sent to you from the Community portal. If you have any
      questions or concerns, please contact the admin.
    </p>
  </div>
</body>
</html>
      
      `
    };

    // console.log(emailData, "emailData");

    try {
      await axios.post( 
        "https://realcommunityportal-production-f1b9.up.railway.app/api/email/send-blast", 
        // "https://development-build-production.up.railway.app",
        // "http://localhost:8000/api/email/send-blast",
        emailData
      );
      // console.log("Email sent successfully", emailData);
    } catch (error) {
      console.error("Error sending email: ", error);
    }
  }

  const handleUpload = async (e) => {
    const file = e.target.files[0];

    const filename = `${Math.floor(Math.random() * 1000000000)}`;

    if (file) {
      const { data, error } = await supabase.storage
        .from("media")
        .upload(`${filename}.${file.name}`, file);

      const { data: publicUrl, error: publicUrlError } = await supabase.storage
        .from("media")
        .getPublicUrl(`${filename}.${file.name}`);

      if (error) {
        console.log(error);
      }

      // console.log(publicUrl, "publicUrl")
      setCoverImage(publicUrl.publicUrl);

      const images = getAllImages();

      return images;
    }
    return "no file";
  };

  const submitResource = async (titleValue, descriptionVaue, editorValue) => {
    let submitError = []
    if (
      !titleValue ||
      !descriptionVaue ||
      !editorValue ||
      !coverImage ||
      selectedOptions.length < 1
    ) {
      if (selectedOptions.length < 1) {
        submitError.push("Please select at least one role.")
      }
      if (!titleValue) {
        submitError.push("Please add a title.")
      }
      if (!descriptionVaue) {
        submitError.push("Please add a description.")
      }
      if (!editorValue) {
        submitError.push("Please add content.")
      }
      if (!coverImage) {
        submitError.push("Please add a cover image.")
      }
      return alert(submitError.join("\n"))
    }

    // console.log(coverImage, "coverImage")

    // console.log(expirationDate, "expirationDate")

    const { data, error } = await supabase
      .from("announcement")
      .insert([
        {
          title: titleValue,
          description: descriptionVaue,
          info: editorValue,
          cover_image: coverImage,
          expiration_date: expirationDate || null,
          email_blast: emailBlast,
          expired: false,
        },
      ])
      .select("*");

    if (error) {
      console.error("Error fetching options: ", error);
    } else {
      const roleId = await getRole(selectedOptions);
      console.log(roleId);
      for (let i = 0; i < roleId.length; i++) {
        const newRoleId = roleId[i].id;
        const announcementId = data[0].id;
        const { data: announcement_role, error: announcement_role_error } = await supabase
          .from("announcement_role")
          .insert([
            {
              announcement_id: announcementId,
              role_id: newRoleId,
            },
          ])
          .select("*");

        if (announcement_role_error) {
          console.error("Error fetching options: ", announcement_role_error);
        } else {
          console.log(announcement_role);
        }
      }

      if (emailBlast) {
        const testMode = false;
        let users;

        if (testMode) {
          users = [
            { email: 'kyle.parkin@realcommunity.com' },
            // { email: 'aaron.shalosky@realcommunity.com' },
            // { email: 'emily.stone@realcommunity.com' },
            // { email: 'dave.lewis@realcommunity.com' },
          ];
          
        } else {
          const roleId = await getRole(selectedOptions);
          if (roleId.some((role) => role.id === 2)) {
            roleId.push({ id: 1 });
          }

          // console.log('Role IDs:', roleId);
          const { data: userData, error: usersError } = await supabase
            .from('profile_role')
            .select('*')
            .in('role_id', roleId.map((role) => role.id));

          if (usersError) {
            console.error('Error fetching user emails:', usersError);
            return; // Stop the execution if there's an error
          }

          // Get the user IDs from the data
          const userIds = userData.map((user) => user.user_id);

          // Get the emails for the users with the IDs we got
          const { data: usersData, error: usersDataError } = await supabase
            .from('profiles')
            .select('email')
            .in('id', userIds);

          if (usersDataError) {
            console.error('Error fetching user emails:', usersDataError);
            return; // Stop the execution if there's an error
          }

          users = usersData;

          // console.log('Users:', users);


        }

        // Prepare promises for sending emails
        const emailPromises = users.map((user) => {
          return sendEmail(user, titleValue, descriptionVaue, editorValue, coverImage);
        });

        try {
          // Wait for all emails to be sent
          await Promise.all(emailPromises);
          // console.log('All emails sent successfully', emailPromises);
        } catch (error) {
          console.error('Error sending one or more emails:', error);
          return; // Stop the execution if sending any email fails
        }
      }


      rolesId = [];
      clearContent();
      window.location.href = `/`;
    }
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedOptions((prev) => [...prev, event.target.value]);
    } else {
      setSelectedOptions((prev) =>
        prev.filter((option) => option !== event.target.value)
      );
    }
  };


  return (
    <div className="flex w-full overflow-hidden text-left text-white font-inter">
      <MainLayoutWrapper>
        <div className=" p-5 ">
          <div className=" py-2 text-white">
            <div className="w-auto sm:flex sm:justify-between text-center items-center p-4 max-sm:pt-0 border-solid border-lightGray border-[.1px]">
              <h1 className="text-[1.2rem] font-bold">Create Announcement</h1>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 cursor-pointer text-white bg-communityBlue hover:bg-communityBlue/80 rounded-md transition-colors duration-200"
              >
                Create Announcement
              </button>
            </div>
            <form className="w-auto border-solid border-lightGray border-[.1px] p-4">
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-title"
                  >
                    Title
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-900 text-white border border-gray-900 rounded py-3 mb-3 leading-tight focus:border-gray focus:outline-none rounded-md border-solid border-[.2px]"
                    id="grid-title"
                    type="text"
                    placeholder="Title"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-description"
                  >
                    Description
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-900 text-white border border-gray-900 rounded py-3 mb-3 leading-tight focus:border-gray focus:outline-none focus:outline-[.1px] rounded-md border-solid border-[.1px] border-none resize-none"
                    id="grid-description"
                    placeholder="Description"
                  ></input>
                </div>
              </div>

              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-description"
                  >
                    Cover Image
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleUpload(e)}
                    className="appearance-none block w-full bg-gray text-white border border-gray rounded py-3 mb-3 leading-tight focus:border-gray focus:outline-none focus:outline-[.1px] rounded-md border-solid border-[.1px] border-none resize-none"
                    id="grid-description"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  {/* create a drop down that you can make multiple selections */}
                  <div className="w-full">
                    <label
                      className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                      htmlFor="grid-description"
                    >
                      Who is this announcement for?
                    </label>
                    <div className="flex flex-row space-x-4 p-2 bg-gray border-lightGray rounded">
                      {options.map((option, index) => (
                        <label key={index} className="p-2">
                          <input
                            type="checkbox"
                            value={option.role_name}
                            onChange={handleCheckboxChange}
                            className="mr-2"
                          />
                          {option.role_name}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-[8rem]">
                <div className="w-[8rem] mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-expiration-date"
                  >
                    Expiration Date
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-900 text-white border border-gray-900 rounded py-3 mb-3 leading-tight focus:border-gray focus:outline-none rounded-md border-solid border-[.2px]"
                    id="grid-expiration-date"
                    type="date"
                    value={expirationDate}
                    onChange={(e) => setExpirationDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="email-blast"
                  >
                    Send Notification to All Users
                  </label>
                  <label className="p-2">
                    <input
                      className="mr-2 leading-tight"
                      id="email-blast"
                      type="checkbox"
                      checked={emailBlast}
                      onChange={(e) => setEmailBlast(e.target.checked)}
                    />
                    Email
                  </label>
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div
                  style={{
                    width: "inherit",
                  }}
                  className=""
                >
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-content"
                  >
                    Content
                  </label>
                  <div className="w-full">
                    <MainEditor editor={editor} setEditor={setEditor} />
                  </div>
                </div>
              </div>
              <div className="w-auto flex justify-end items-center pl-4 pr-4 pb-4">
              </div>
            </form>
            <div className="sm:text-right text-center border-solid border-lightGray border-[.1px] p-4">
              <button
                onClick={handleSubmit}
                className="px-4 py-2 cursor-pointer text-white bg-communityBlue hover:bg-communityBlue/80 rounded-md transition-colors duration-200"
              >
                Create Announcement
              </button>
            </div>
          </div>
        </div>
      </MainLayoutWrapper>
    </div>
  )
}

export default AddAnnouncement