import { info } from "console";
import React, { useState, FunctionComponent, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import AnnouncementModal from "../announcement/AnnouncementModal";
import supabaseConnect from "../../utils/supabaseConnect";
import { communityLogo1 } from "../../assets/index";
import EventModal from "../../components/Events/EventModal";
import { CardProps, AnnouncementData } from "./types";
import clsx from "clsx";

const Card: React.FC<CardProps> = ({
  cover_image,
  tag,
  title,
  description,
  info,
  table_name,
  id,
  docId,
  link,
  start_date,
  location,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeAnnouncement, setActiveAnnouncement] = useState<AnnouncementData[]>([]);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState<any[]>([]);
  const [beingEdited, setBeingEdited] = useState(false);

  const supabase = supabaseConnect();

  const handleCardClick = async (id: number, table_name: string, docId: string) => {
    if (table_name === "page" || table_name === "link") {
      window.open(link, "_blank");
      return;
    }

    const { data, error } = await supabase
      .from(table_name)
      .select("*")
      .eq("id", id)
      .single();

    if (error) {
      console.error(`Error fetching ${table_name}:`, error);
      return;
    }

    if (!data) {
      console.error(`No ${table_name} found`);
      return;
    }

    if (table_name === "announcement") {
      setActiveAnnouncement([data]);
      setIsModalOpen(true);
    } else if (table_name === "event") {
      setActiveEvent([data]);
      setIsEventModalOpen(true);
    }
  };

  return (
    <>
      <AnnouncementModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        activeAnnouncment={activeAnnouncement}
      />
      
      <EventModal
        isOpen={isEventModalOpen}
        onClose={() => setIsEventModalOpen(false)}
        activeEvent={activeEvent}
        setBeingEdited={setBeingEdited}
        setShowEventModal={setIsEventModalOpen}
        setActiveEvent={setActiveEvent}
      />

      <div
        role="button"
        tabIndex={0}
        onClick={() => handleCardClick(id, table_name, docId)}
        onKeyDown={(e) => e.key === 'Enter' && handleCardClick(id, table_name, docId)}
        className="group flex flex-col rounded-lg shadow-lg overflow-hidden min-h-[19rem] max-h-[19rem] bg-black cursor-pointer transition-all duration-200 hover:shadow-xl"
        aria-label={`View details for ${title}`}
      >
        <div className="flex-1 p-4 flex flex-col justify-between">
          <div className="space-y-4">
            <p className="text-sm font-medium text-tourquoise">
              {tag}
            </p>
            <div className="space-y-2">
              <h3 className={clsx(
                "text-white font-bold break-words",
                title.length > 50 ? "text-sm max-h-[3rem]" : "text-base"
              )}>
                {title}
              </h3>
              <p className={clsx(
                "text-white break-words",
                description.length > 100 ? "text-xs max-h-[1rem] overflow-y-hidden" : "text-sm"
              )}>
                {description}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-end mt-4">
            <span className="text-[.8rem] text-white group-hover:translate-x-1 transition-transform duration-200">
              View Page <span aria-hidden="true">&rarr;</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

interface CardGridProps {
  data: CardProps[];
}

const CardGrid: React.FC<CardGridProps> = ({ data }) => (
  <div className="flex flex-wrap responsive  mb-[13rem] w-full">
    {data?.map((card, index) => (
      <div className="p-4 w-[17rem] pr-[1rem] " key={index}>
        <Card {...card} />
      </div>
    ))}
  </div>
);

export default CardGrid;
