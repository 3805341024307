import React, { useEffect, useState } from 'react';
import supabaseConnect from '../../../../../utils/supabaseConnect';
import { Tooltip } from '@mui/material';

/**
 * Base user interface containing essential user information
 * @interface User
 */
interface User {
  id: string;
  first_name: string;
  last_name: string;
  profile_image_url: string | null;
  created_at?: string;
  activity_count?: number;
}

interface UserListProps {
  users: User[];
  showActivityCount?: boolean;
}

/**
 * Generates user initials from first and last name
 * @param {string} firstName - User's first name
 * @param {string} lastName - User's last name
 * @returns {string} Uppercase initials
 */
const getInitials = (firstName: string, lastName: string) => {
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
};

const getRandomColor = (initials: string) => {
  const colors = [
    'bg-blue-500', 'bg-purple-500', 'bg-pink-500', 
    'bg-indigo-500', 'bg-teal-500', 'bg-green-500'
  ];
  const index = initials.charCodeAt(0) % colors.length;
  return colors[index];
};

const UserList: React.FC<UserListProps> = ({ users, showActivityCount }) => {
  return (
    <div className="space-y-2">
      {users.map((user, index) => (
        <div 
          key={user.id}
          className="flex items-center justify-between py-2 hover:bg-gray-50 rounded-lg transition-colors"
          style={{
            borderBottom: index !== users.length - 1 ? '1px solid #E5E7EB' : 'none',
            marginBottom: index !== users.length - 1 ? '8px' : '0'
          }}
        >
          <div className="flex items-center space-x-3">
            {user.profile_image_url ? (
              <img
                src={user.profile_image_url}
                alt={`${user.first_name} ${user.last_name}`}
                className="w-8 h-8 rounded-full object-cover"
              />
            ) : (
              <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white text-sm ${getRandomColor(getInitials(user.first_name, user.last_name))}`}>
                {getInitials(user.first_name, user.last_name)}
              </div>
            )}
            <div>
              <div className="font-medium text-sm">
                {user.first_name} {user.last_name}
              </div>
              {!showActivityCount && user.created_at && (
                <div className="text-xs text-gray-500">
                  {new Date(user.created_at).toLocaleDateString()}
                </div>
              )}
            </div>
          </div>

          {showActivityCount && (
            <div className="text-xs text-gray-500">
              {user.activity_count} links clicked
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

/**
 * Extended user interface with point system metrics
 * @interface UserWithPoints
 * @extends {User}
 */
interface UserWithPoints extends User {
  points: number;
  vendors_created: number;
  links_clicked: number;
  ratings_given: number;
  partner_ratings_given: number;
  survey_responses_given: number;
}

interface PartnerRating {
  profile_id: string;
  count: number;
}

/**
 * Tooltip component showing user activity breakdown and points
 * @component
 * @param {Object} props
 * @param {number} props.vendors - Number of vendors created
 * @param {number} props.clicks - Number of links clicked
 * @param {number} props.ratings - Number of vendor ratings given
 * @param {number} props.partnerRatings - Number of partner ratings given
 * @param {number} props.surveyResponses - Number of survey questions answered
 */
const UserActivityTooltip: React.FC<{ 
  vendors: number; 
  clicks: number; 
  ratings: number;
  partnerRatings: number;
  surveyResponses: number;
}> = ({ vendors, clicks, ratings, partnerRatings, surveyResponses }) => {
  // Ensure all values are numbers
  const vendorsCount = Number(vendors) || 0;
  const clicksCount = Number(clicks) || 0;
  const ratingsCount = Number(ratings) || 0;
  const partnerRatingsCount = Number(partnerRatings) || 0;
  const surveyResponsesCount = Number(surveyResponses) || 0;
  const totalPoints = (vendorsCount * 10) + clicksCount + (ratingsCount * 5) + (partnerRatingsCount * 5) + (surveyResponsesCount * 15);

  return (
    <div className="p-3 bg-white rounded-lg shadow-lg border border-gray-200 w-48">
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <span className="text-xs font-medium text-black">Vendors Created</span>
          <span className="text-xs font-medium text-black">{vendorsCount}</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-xs font-medium text-black">Links Clicked</span>
          <span className="text-xs font-medium text-black">{clicksCount}</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-xs font-medium text-black">Vendor Ratings</span>
          <span className="text-xs font-medium text-black">{ratingsCount}</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-xs font-medium text-black">Partner Ratings</span>
          <span className="text-xs font-medium text-black">{partnerRatingsCount}</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-xs font-medium text-black">Survey Questions Answered</span>
          <span className="text-xs font-medium text-black">{surveyResponsesCount}</span>
        </div>
        <div className="pt-2 border-t">
          <div className="flex justify-between items-center">
            <span className="text-xs font-medium text-black">Total Points</span>
            <span className="text-xs font-medium text-black">{totalPoints}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Main component displaying most active users and recently added members
 * Points System:
 * - 10 points per vendor created
 * - 1 point per link clicked
 * - 5 points per vendor rating
 * - 5 points per partner rating
 * - 5 points per survey question answered
 * @component
 */
export const MostActiveUsers: React.FC = () => {
  const [activeUsers, setActiveUsers] = useState<UserWithPoints[]>([]);
  const [recentUsers, setRecentUsers] = useState<User[]>([]);
  const supabase = supabaseConnect();

  /**
   * Fetches and processes user data to calculate activity points
   * Combines data from multiple tables:
   * - user_sessions for link clicks
   * - vendors for created vendors
   * - vendor_ratings for vendor ratings
   * - partner_ratings for partner ratings
   * - survey_responses for survey questions answered
   */
  useEffect(() => {
    const fetchMostActiveUsers = async () => {
      // Main user data query
      const { data: userData, error: userError } = await supabase
        .from('profiles')
        .select(`
          id,
          first_name,
          last_name,
          profile_image_url,
          user_sessions!inner(count),
          vendors!inner(count),
          vendor_ratings!inner(count),
          partner_ratings!inner(count)
        `)
        .not('first_name', 'is', null)
        .not('last_name', 'is', null)
        .neq('first_name', '')
        .neq('last_name', '');

      if (userError) {
        console.error('Error fetching user data:', userError);
        return;
      }

      // Separate query for survey responses
      const { data: surveyData } = await supabase
        .from('survey_responses')
        .select('profile_id')
        .in('profile_id', userData.map(u => u.id));

      // Create a map of profile_id to survey count
      const surveyCountMap = new Map(
        userData.map(user => [
          user.id,
          surveyData?.filter(s => s.profile_id === user.id).length || 0
        ])
      );

      const usersWithPoints = userData
        .map(user => {
          const linksClicked = user.user_sessions?.[0]?.count || 0;
          const vendorsCreated = user.vendors?.[0]?.count || 0;
          const ratingsGiven = user.vendor_ratings?.[0]?.count || 0;
          const partnerRatingsGiven = user.partner_ratings?.[0]?.count || 0;
          const surveyQuestionsAnswered = surveyCountMap.get(user.id) || 0;

          const totalPoints = (vendorsCreated * 10) + 
                             linksClicked + 
                             (ratingsGiven * 5) + 
                             (partnerRatingsGiven * 5) +
                             (surveyQuestionsAnswered * 5);

          return {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            profile_image_url: user.profile_image_url,
            points: totalPoints,
            vendors_created: vendorsCreated,
            links_clicked: linksClicked,
            ratings_given: ratingsGiven,
            partner_ratings_given: partnerRatingsGiven,
            survey_responses_given: surveyQuestionsAnswered
          };
        })
        .sort((a, b) => b.points - a.points)
        .slice(0, 6);

      setActiveUsers(usersWithPoints);
    };

    /**
     * Fetches the two most recently added users
     * Filters out users without complete profiles (missing names)
     */
    const fetchRecentUsers = async () => {
      const { data, error } = await supabase
        .from('profiles')
        .select('id, first_name, last_name, profile_image_url, created_at')
        .not('first_name', 'is', null) 
        .not('last_name', 'is', null)   
        .neq('first_name', '')
        .neq('last_name', '')           
        .order('created_at', { ascending: false })
        .limit(2);

      if (error) {
        console.error('Error fetching recent users:', error);
        return;
      }

      setRecentUsers(data);
    };

    fetchMostActiveUsers();
    fetchRecentUsers();
  }, []);

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <div className="grid grid-cols-2 gap-6">
        <div>
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">Recently Added</h2>
            <div className="text-xs text-gray-500">
              Newest members
            </div>
          </div>
          <UserList users={recentUsers} />
        </div>

        <div className="border-l pl-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">Most Influential</h2>
            <div className="text-xs text-gray-500">
              Based on activity
            </div>
          </div>
          <div className="space-y-2">
            {activeUsers.map((user, index) => (
              <div 
                key={user.id}
                className="flex items-center justify-between py-2 hover:bg-gray-50 rounded-lg transition-colors"
                style={{
                  borderBottom: index !== activeUsers.length - 1 ? '1px solid #E5E7EB' : 'none',
                  marginBottom: index !== activeUsers.length - 1 ? '8px' : '0'
                }}
              >
                <div className="flex items-center space-x-3">
                  {user.profile_image_url ? (
                    <img
                      src={user.profile_image_url}
                      alt={`${user.first_name} ${user.last_name}`}
                      className="w-8 h-8 rounded-full object-cover"
                    />
                  ) : (
                    <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white text-sm ${getRandomColor(getInitials(user.first_name, user.last_name))}`}>
                      {getInitials(user.first_name, user.last_name)}
                    </div>
                  )}
                  <Tooltip 
                    title={
                      <UserActivityTooltip 
                        vendors={user.vendors_created} 
                        clicks={user.links_clicked}
                        ratings={user.ratings_given}
                        partnerRatings={user.partner_ratings_given}
                        surveyResponses={user.survey_responses_given}
                      />
                    }
                    arrow
                    placement="top"
                  >
                    <div className="font-medium text-sm cursor-help">
                      {user.first_name} {user.last_name}
                    </div>
                  </Tooltip>
                </div>

                <div className="text-xs font-medium text-gray-900">
                  {user.points} points
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};